import { getDateRangeString } from "@greenflagdate/shared";
import { useReq } from "@larner.dev/use-req";
import { Dialog, DialogProps, Paragraph } from "evergreen-ui";
import { apiReq } from "../../lib/apiReq";
import { Alert } from "../Alert/Alert";
import { useSparkDates } from "../../lib/useSparkDates";
import styles from "./CancelSparkConfirmationDialog.module.css";

interface CancelSparkConfirmationDialog extends DialogProps {
  onCancelReservation: () => void;
}

export const CancelSparkConfirmationDialog = ({
  onCancelReservation,
  ...props
}: CancelSparkConfirmationDialog) => {
  const [req, reqState] = useReq(apiReq);
  const sparkDates = useSparkDates();
  return (
    <Dialog
      title="Hope to see you next time"
      confirmLabel="Cancel Registration"
      cancelLabel="Nevermind"
      onConfirm={async (close) => {
        const result = await req.delete("/spark/preferences/:key", {});
        if (result.success) {
          onCancelReservation();
          close();
        }
      }}
      isConfirmLoading={reqState.loading}
      containerProps={{
        className: styles.container,
      }}
      {...props}
    >
      {reqState.error && <Alert title={reqState.error.code}></Alert>}
      <Paragraph size={500} marginBottom="1rem">
        Are you sure you want to cancel your Spark registration for the week of{" "}
        {getDateRangeString(sparkDates[0], sparkDates[sparkDates.length - 1])}?
      </Paragraph>
    </Dialog>
  );
};
