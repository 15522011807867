import { Pane, PaneProps } from "evergreen-ui";
import { PropsWithChildren } from "react";
import { useGFTheme } from "../../lib/themes";

interface ProgressBarProps extends PropsWithChildren<PaneProps<"div">> {
  progress: number;
}
export const ProgressBar = ({ progress, ...props }: ProgressBarProps) => {
  const { colors } = useGFTheme();
  return (
    <Pane
      height="0.375rem"
      width="100%"
      backgroundColor={colors.neutralMedium}
      borderRadius="0.375rem"
      overflow="hidden"
      {...props}
    >
      <Pane
        width={`${progress}%`}
        backgroundColor={colors.greenLight}
        height="100%"
      />
    </Pane>
  );
};
