import { useCallback, useEffect, useMemo } from "react";
import styles from "./Events.module.css";
import { Loader } from "../../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { Link as StyledLink } from "../../../components/Link/Link";
import { Meta } from "../../../components/Meta/Meta";
import { H2, H3 } from "../../../components/Heading/Heading";
import { Button, Pane, Paragraph } from "evergreen-ui";
import { useReq } from "@larner.dev/use-req";
import {
  getDateRangeString,
  getMondayForSpark,
  Spark,
  SparkPreference,
  SparkWithRegistrationSummary,
} from "@greenflagdate/shared";
import { useSparkDates } from "../../../lib/useSparkDates";
import { useGFTheme } from "../../../lib/themes";
import { getCurrentSparkPage } from "../../../lib/getCurrentSparkPage";
import { apiReq } from "../../../lib/apiReq";
import { useStoreData } from "../../../lib/store/store";
import { isLoggedIn } from "../../../lib/isLoggedIn";

import sparkHero from "./images/sparkHero.png";
import { NewCard } from "../../../components/Card/Card";
import { SparkCard } from "../../../components/SparkCard/SparkCard";
import dayjs from "dayjs";
const ageInRange = (age: number, spark: Spark) => {
  if (age < spark.min_age) {
    return false;
  }
  if (spark.max_age && age > spark.max_age) {
    return false;
  }
  return true;
};
export const Events = () => {
  const navigate = useNavigate();
  const [sparksReq, sparksReqState] =
    useReq<SparkWithRegistrationSummary[]>(apiReq);
  const [mySparksReq, mySparksReqState] =
    useReq<SparkWithRegistrationSummary[]>(apiReq);
  const store = useStoreData();
  const loggedIn = useMemo(() => isLoggedIn(store), [store]);
  const { colors } = useGFTheme();
  const sparkDates = useSparkDates();
  const [latestPrefReq, latestPrefReqState] = useReq<SparkPreference | null>(
    apiReq
  );
  const currentKey = useMemo(
    () => getMondayForSpark().format("YYYY-MM-DD"),
    []
  );
  const refreshMySparks = useCallback(() => {
    if (loggedIn) {
      mySparksReq.get("/user/sparks");
    }
  }, [loggedIn, mySparksReq]);
  useEffect(() => {
    sparksReq.get(`/spark/current`);
  }, [sparksReq]);
  useEffect(() => {
    refreshMySparks();
  }, [refreshMySparks]);
  useEffect(() => {
    latestPrefReq.get("/spark/preferences/latest");
  }, [latestPrefReq]);
  const unregisteredEvents = useMemo(() => {
    const unregistered =
      sparksReqState.result?.filter(
        (s1) =>
          !loggedIn || !mySparksReqState.result?.some((s2) => s1.id === s2.id)
      ) || [];
    unregistered.sort((a, b) => {
      const aEnds = new Date(a.ends_at).getTime();
      const bEnds = new Date(b.ends_at).getTime();
      if (!loggedIn) {
        return aEnds - bEnds;
      } else {
        const age = dayjs().diff(store.user?.birth_date, "years");
        const aInRange = ageInRange(age, a);
        const bInRange = ageInRange(age, b);
        if (aInRange && !bInRange) {
          return -1;
        } else if (bInRange && !aInRange) {
          return 1;
        }
        return aEnds - bEnds;
      }
    });
    return unregistered;
  }, [
    loggedIn,
    mySparksReqState.result,
    sparksReqState.result,
    store.user?.birth_date,
  ]);

  if (!sparksReqState.result || sparksReqState.loading) {
    return (
      <div className={styles.loadingContainer}>
        <Loader />
      </div>
    );
  }

  if (!sparksReqState.loading) {
    if (sparksReqState.error) {
      return <div>{sparksReqState.error.toString()}</div>;
    }
  }

  return (
    <>
      <Meta>
        <title>Green Flag Events</title>
        <meta property="og:title" content="Green Flag Events" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.greenflagdate.com/events"
        />
      </Meta>
      <Pane
        maxWidth="40rem"
        marginX="auto"
        marginTop="1.5rem"
        paddingX="1.5rem"
      >
        <H2 marginBottom="1.5rem">
          {loggedIn && store.user?.first_name
            ? `Welcome ${store.user.first_name}`
            : "Green Flag Events"}
          !
        </H2>
        {!loggedIn && (
          <Pane marginBottom="2rem">
            All Events are currently in Atlanta. If you are interested in
            hosting an event in your city please{" "}
            <StyledLink to="/contact">let us know!</StyledLink>
          </Pane>
        )}
        <NewCard
          borderWidth="0"
          padding={0}
          overflow="hidden"
          display="flex"
          marginBottom="2rem"
        >
          <Pane
            backgroundImage={`url(${sparkHero})`}
            backgroundSize="cover"
            backgroundPosition="50% 30%"
            flex={1}
          />
          {loggedIn && latestPrefReqState.result?.key === currentKey ? (
            <Pane flex={2} paddingX="1.5rem" paddingY="1rem">
              <H3 textAlign="left" marginBottom="0.5rem">
                🎉 You're in for a Spark! 🎉
              </H3>
              <Paragraph size={300} marginBottom="1rem">
                You've set your preferences for the next batch of sparks running from{" "}
                {getDateRangeString(
                  sparkDates[0],
                  sparkDates[sparkDates.length - 1]
                )}. <strong>Thursday you'll get an email in your inbox</strong>{" "}
                notifying you if you've been placed in an event along with the
                details of that event.
              </Paragraph>
              <Pane
                height={1}
                background="tint2"
              />
              <Paragraph size={300} marginBottom="1rem">
                If your plans change and you need to cancel, please email us at <StyledLink to={"mailto:hi@greenflagdate.com"} >hi@greenflagdate.com</StyledLink> as soon as possible.
                Got questions? Feel free to reach out. In the meantime, get ready for a fun time!
              </Paragraph>

              <Pane
                display="flex"
                gap="0.5rem"
                className={styles.signedUpSparkButtons}
              >
                <Button
                  size="small"
                  onClick={() => navigate("/spark/preferences")}
                  selectors={{
                    "&:hover": {
                      backgroundColor: colors.neutralLight,
                    },
                    "&": {
                      color: "black",
                    },
                  }}
                >
                  Update Preferences
                </Button>
                {/* <CancelSparkButton
                  size="small"
                  appearance="primary"
                  backgroundColor={colors.redPrimary}
                  selectors={{
                    "&:hover": {
                      backgroundColor: colors.redDark,
                      color: "white",
                    },
                    "&": {
                      color: "black",
                    },
                  }}
                  onCancelReservation={() =>
                    latestPrefReq.get("/spark/preferences/latest")
                  }
                /> */}
              </Pane>
            </Pane>
          ) : (
            <Pane flex={2} paddingX="1.5rem" paddingY="1rem">
              <H3 textAlign="left" marginBottom="0.5rem">
                Sign up for a Spark!
              </H3>
              <Paragraph size={300} marginBottom="1rem">
                Join others for a fun, hostless activity! The next ones run{" "}
                {getDateRangeString(
                  sparkDates[0],
                  sparkDates[sparkDates.length - 1]
                )}
                .
              </Paragraph>

              <Button
                size="small"
                appearance="primary"
                width="100%"
                backgroundColor={colors.greenLight}
                maxWidth="14rem"
                selectors={{
                  "&:hover": {
                    backgroundColor: colors.greenPrimary,
                  },
                  "&": {
                    color: "black",
                  },
                }}
                onClick={() =>
                  navigate(
                    loggedIn ? "/spark/preferences" : getCurrentSparkPage()
                  )
                }
              >
                SIGN UP
              </Button>
            </Pane>
          )}
        </NewCard>
        {loggedIn && !!mySparksReqState.result?.length && (
          <>
            <Paragraph size={400} color={colors.greenDark}>
              MY RESERVATIONS
            </Paragraph>
            {mySparksReqState.result.map((spark) => (
              <SparkCard
                spark={spark}
                isRegistered={true}
                onCancelReservation={refreshMySparks}
                onRegister={refreshMySparks}
              />
            ))}
          </>
        )}
        {unregisteredEvents.length ? (
          <>
            <Paragraph size={400} color={colors.greenDark}>
              OTHER UPCOMING EVENTS
            </Paragraph>
            {unregisteredEvents.map((spark) => (
              <SparkCard
                spark={spark}
                isRegistered={false}
                onCancelReservation={refreshMySparks}
                onRegister={refreshMySparks}
              />
            ))}
          </>
        ) : (
          <Pane marginBottom="2rem">
            We don't have any events scheduled at this time. If you are
            interested in hosting an event in your city please{" "}
            <StyledLink to="/contact">let us know!</StyledLink>
          </Pane>
        )}
      </Pane>
    </>
  );
};
