import {
  Pane,
  Button,
  IconButton,
  PlusIcon,
  TextInput,
  Paragraph,
} from "evergreen-ui";
import { useCallback, useMemo, useState } from "react";
import { store } from "../../../lib/store/store";
import { ActionType } from "../../../lib/store/storeActions";
import { useNavigate } from "../../../lib/useNavigate";
import { useOutletContext } from "react-router-dom";
import { ProgressScreen } from "../../../components/ProgressScreen/ProgressScreen";
import { AppContext } from "../../app/App/appTypes";
import { VerticalGroup } from "../../../components/VerticalGroup/VerticalGroup";
import { sparkScreenMap } from "./sparkScreens";
import { ToggleButton } from "../../../components/ToggleButton/ToggleButton";
import { useSparkActivityData } from "../../../lib/sparkHelpers";
import { H3 } from "../../../components/Heading/Heading";
import { Loader } from "../../../components/Loader/Loader";

export const SparkActivities = () => {
  const navigate = useNavigate();
  const { storeData } = useOutletContext<AppContext>();
  const { loading: sparkActivitiesLoading, sparkActivities } =
    useSparkActivityData();
  const activities = useMemo(
    () => storeData.sparkRegistration.activities || [],
    [storeData.sparkRegistration.activities]
  );
  const [customSpark, setCustomSpark] = useState<string>("");
  const next = useCallback(async () => {
    navigate(`/spark/${sparkScreenMap["activities"].next}`);
  }, [navigate]);

  const toggle = useCallback(
    (val: string) => () => {
      store.dispatch({
        type: ActionType.UpdateSparkRegistration,
        params: {
          activities: activities.includes(val)
            ? activities.filter((n) => n !== val)
            : [...activities, val],
        },
      });
    },
    [activities]
  );

  return (
    <ProgressScreen
      title="Which activities are you interested in?"
      progress={sparkScreenMap["activities"].progress}
      prev={`/spark`}
      canContinue={activities.length >= 1}
      titleAlign="center"
      onContinue={next}
    >
      <Pane
        display="flex"
        flexDirection="column"
        gap="0.75rem"
        alignItems="flex-end"
      >
        <Button
          size="small"
          onClick={() => {
            store.dispatch({
              type: ActionType.UpdateSparkRegistration,
              params: {
                activities:
                  activities.length >= sparkActivities.length
                    ? []
                    : sparkActivities.map((a) => a.attributes.name),
              },
            });
          }}
        >
          {activities.length === sparkActivities.length
            ? "Select None"
            : "Select All"}
        </Button>
        {sparkActivitiesLoading ? (
          <Pane display="flex" justifyContent="center" paddingY="2rem">
            <Loader />
          </Pane>
        ) : (
          <>
            <H3 marginTop="1rem" marginBottom="0.25rem" width="100%">
              Spark Basic Events
            </H3>
            <Paragraph size={500} width="100%">
              Admission: $15
            </Paragraph>
            <VerticalGroup
              marginTop="1rem"
              gap="0.25rem"
              selectors={{
                "& > button": {
                  borderRadius: "1rem",
                },
              }}
            >
              {sparkActivities
                .filter((a) => !a.attributes.isPremium)
                .map((a) => (
                  <ToggleButton
                    key={a.id}
                    margin="0.25rem"
                    height="2rem"
                    isActive={activities.includes(a.attributes.name)}
                    onClick={toggle(a.attributes.name)}
                  >
                    {a.attributes.name}
                  </ToggleButton>
                ))}
            </VerticalGroup>
            <H3 marginTop="1rem" marginBottom="0.25rem" width="100%">
              Spark Premium Events
            </H3>
            <Paragraph size={500} width="100%">
              Admission: $30
            </Paragraph>
            <Paragraph size={500} width="100%">
              Includes the price of all associated activity fees (ie. equipment,
              entrance fee, etc.)
            </Paragraph>
            <VerticalGroup
              marginTop="1rem"
              gap="0.25rem"
              selectors={{
                "& > button": {
                  borderRadius: "1rem",
                },
              }}
            >
              {sparkActivities
                .filter((a) => a.attributes.isPremium)
                .map((a) => (
                  <ToggleButton
                    key={a.id}
                    margin="0.25rem"
                    height="2rem"
                    isActive={activities.includes(a.attributes.name)}
                    onClick={toggle(a.attributes.name)}
                  >
                    {a.attributes.name}
                  </ToggleButton>
                ))}
            </VerticalGroup>
            <Pane
              is="form"
              display="flex"
              marginTop="1rem"
              width="100%"
              onSubmit={(e: React.FormEvent) => {
                e.preventDefault();
                if (customSpark) {
                  toggle(customSpark)();
                  setCustomSpark("");
                }
              }}
            >
              <TextInput
                width="100%"
                placeholder="Add your own!"
                borderTopRightRadius="0"
                borderBottomRightRadius="0"
                value={customSpark}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCustomSpark(e.target.value)
                }
              />
              <IconButton
                icon={<PlusIcon color="white" />}
                appearance="primary"
                borderRadius="5px"
                borderTopLeftRadius="0"
                borderBottomLeftRadius="0"
                height="46px"
              />
            </Pane>
            <VerticalGroup
              marginTop="1rem"
              gap="0.25rem"
              selectors={{
                "& > button": {
                  borderRadius: "1rem",
                },
              }}
            >
              {activities
                .filter(
                  (a) => !sparkActivities.some((ac) => ac.attributes.name === a)
                )
                .map((a) => (
                  <ToggleButton
                    key={a}
                    margin="0.25rem"
                    height="2rem"
                    isActive={activities.includes(a)}
                    onClick={toggle(a)}
                  >
                    {a}
                  </ToggleButton>
                ))}
            </VerticalGroup>
          </>
        )}
      </Pane>
    </ProgressScreen>
  );
};
