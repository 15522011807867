import { getDateTimeRangeString, Spark } from "@greenflagdate/shared";
import { useReq } from "@larner.dev/use-req";
import {
  Dialog,
  DialogProps,
  Icon,
  MapMarkerIcon,
  Paragraph,
} from "evergreen-ui";
import { apiReq } from "../../lib/apiReq";
import { Alert } from "../Alert/Alert";
import styles from "./CancelEventRegistrationConfirmationDialog.module.css";

interface CancelEventRegistrationConfirmationDialogProps extends DialogProps {
  spark: Spark;
  onCancelReservation: () => void;
}

export const CancelEventRegistrationConfirmationDialog = ({
  spark,
  onCancelReservation,
  ...props
}: CancelEventRegistrationConfirmationDialogProps) => {
  const [req, reqState] = useReq(apiReq);
  return (
    <Dialog
      title="Hope to see you next time"
      confirmLabel="Cancel my reservation"
      cancelLabel="Go back"
      onConfirm={async (close) => {
        const result = await req.delete("/spark/registration", {
          spark_id: spark.id,
        });
        if (result.success) {
          onCancelReservation();
          close();
        }
      }}
      isConfirmLoading={reqState.loading}
      containerProps={{
        className: styles.container,
      }}
      {...props}
    >
      {reqState.error && <Alert title={reqState.error.code}></Alert>}
      <Paragraph size={500} marginBottom="1rem">
        Are you sure you want to cancel your reservation for{" "}
        <strong>{spark.title}</strong>?
      </Paragraph>

      <Paragraph size={300} marginBottom="0.25rem">
        {getDateTimeRangeString(spark.starts_at, spark.ends_at)}
      </Paragraph>
      <Paragraph
        size={300}
        marginBottom="0.35rem"
        display="flex"
        alignItems="center"
        gap="0.25rem"
      >
        <Icon icon={MapMarkerIcon} /> {spark.location}
      </Paragraph>
    </Dialog>
  );
};
