import { Paragraph, Pane } from "evergreen-ui";
import { useCallback, useMemo } from "react";
import { DateField } from "../../../components/DateField/DateField";
import { useGFTheme } from "../../../lib/themes";
import dayjs from "dayjs";
import { store } from "../../../lib/store/store";
import { ActionType } from "../../../lib/store/storeActions";
import { useNavigate } from "../../../lib/useNavigate";
import { useOutletContext } from "react-router-dom";
import { ProgressScreen } from "../../../components/ProgressScreen/ProgressScreen";
import { AppContext } from "../../app/App/appTypes";
import { sparkScreenMap } from "./sparkScreens";

export const SparkBirthDate = () => {
  const navigate = useNavigate();
  const { colors } = useGFTheme();
  const { storeData } = useOutletContext<AppContext>();
  const birthDate = useMemo(
    () => storeData.sparkRegistration.birthDate || "",
    [storeData.sparkRegistration.birthDate]
  );
  const date = useMemo(() => new Date(birthDate), [birthDate]);
  const validDate = !isNaN(date.getTime()) && date.getFullYear() !== 0;
  const age = useMemo(
    () => (validDate ? dayjs().diff(birthDate, "year") : 0),
    [birthDate, validDate]
  );
  const next = useCallback(async () => {
    store.dispatch({
      type: ActionType.UpdateSparkRegistration,
      params: {
        birthDate,
      },
    });
    navigate(`/spark/${sparkScreenMap["birth-date"].next}`);
  }, [birthDate, navigate]);

  return (
    <ProgressScreen
      title="What's your birth date?"
      progress={sparkScreenMap["birth-date"].progress}
      prev={`/spark/${sparkScreenMap["birth-date"].prev}`}
      canContinue={validDate && age >= 18}
      titleAlign="center"
      onContinue={() => {
        next();
      }}
    >
      <Paragraph size={500} textAlign="center" marginBottom="1.5rem">
        Sparks are grouped by age.
      </Paragraph>
      <Pane
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          next();
        }}
        is="form"
        paddingBottom="2.125rem"
        borderBottom={`1px solid ${colors.grayPrimary}`}
        display="flex"
        justifyContent="center"
      >
        <DateField
          value={birthDate}
          onChange={(birthDate) => {
            store.dispatch({
              type: ActionType.UpdateSparkRegistration,
              params: {
                birthDate,
              },
            });
          }}
          validationMessage={
            !!age &&
            age < 18 &&
            "You must be 18 years or older to use Green Flag"
          }
        />
      </Pane>
      {validDate && (
        <Paragraph
          size={500}
          fontWeight="bold"
          textAlign="center"
          marginTop="2.125rem"
        >
          Age: {age}
        </Paragraph>
      )}
    </ProgressScreen>
  );
};
