import {
  getDateTimeRangeString,
  SparkWithRegistrationSummary,
} from "@greenflagdate/shared";
import { NewCard, NewCardProps } from "../Card/Card";
import {
  Pane,
  Paragraph,
  Button,
  Icon,
  MapMarkerIcon,
  ParagraphProps,
  PaneProps,
} from "evergreen-ui";
import { H3 } from "../Heading/Heading";
import { useGFTheme } from "../../lib/themes";
import { useNavigate } from "react-router-dom";
import { getAgeRangeString, isSparkFull } from "../../lib/sparkHelpers";
import { isLoggedIn } from "../../lib/isLoggedIn";
import { useStoreData } from "../../lib/store/store";
import { StoreSchema } from "../../lib/store/StoreSchema";
import { useMemo, useState } from "react";
import { SparkRegisterDialog } from "../SparkRegisterDialog/SparkRegisterDialog";
import { CancelEventRegistrationConfirmationDialog } from "../CancelEventRegistrationConfirmationDialog/CancelEventRegistrationConfirmationDialog";
import styles from "./SparkCard.module.css";
interface SparkCardProps extends NewCardProps {
  spark: SparkWithRegistrationSummary;
  isRegistered: boolean;
  onCancelReservation: () => void;
  onRegister: () => void;
}

const getFullBannerText = (
  full: ("men" | "women" | "everyone")[],
  store: StoreSchema
) => {
  if (full.includes("everyone")) {
    return "FULL";
  }
  if (isLoggedIn(store)) {
    if (store.user?.gender?.toLowerCase() === "man" && full.includes("men")) {
      return "FULL FOR MEN";
    }
    if (
      store.user?.gender?.toLowerCase() === "woman" &&
      full.includes("women")
    ) {
      return "FULL FOR WOMEN";
    }
  }
  return null;
};

interface CapacityTagProps extends ParagraphProps {
  current: number;
  capacity: number;
  highlightAlmostFull: boolean;
  label: string;
}

const CapacityTag = ({
  current,
  capacity,
  highlightAlmostFull,
  label,
  ...props
}: CapacityTagProps) => {
  const { colors } = useGFTheme();
  const isFull = current >= capacity;
  const isAlmostFull = !isFull && capacity - current <= 3;
  return (
    <Paragraph
      size={300}
      paddingX="0.25rem"
      borderRadius="5px"
      backgroundColor={
        highlightAlmostFull && isAlmostFull
          ? colors.orangePrimary
          : colors.neutralLight
      }
      color={highlightAlmostFull && isAlmostFull ? "white" : "black"}
      {...props}
      opacity={isFull ? 0.65 : 1}
      fontSize="10px"
    >
      {label}: {capacity - current}&nbsp;/&nbsp;{capacity}
    </Paragraph>
  );
};

interface SparkCardImageProps extends PaneProps {
  src: string;
  sparkId: number;
  isRegistered: boolean;
  bannerText: string | null;
}

const SparkImage = ({
  src,
  sparkId,
  isRegistered,
  bannerText,
  ...props
}: SparkCardImageProps) => {
  const { colors } = useGFTheme();
  const navigate = useNavigate();
  return (
    <Pane
      backgroundImage={`url(${src})`}
      backgroundSize="cover"
      backgroundPosition="50% 30%"
      position="relative"
      overflow="hidden"
      cursor="pointer"
      onClick={() => navigate(`/spark/atlanta/${sparkId}`)}
      {...props}
    >
      {!isRegistered && bannerText && (
        <Pane
          position="absolute"
          left="-50px"
          right="-50px"
          top="calc(50% - 15px)"
          height="30px"
          lineHeight="30px"
          textAlign="center"
          transform="rotate(-45deg)"
          fontWeight="bold"
          zIndex={1}
          boxShadow="0 2px 4px rgba(0, 0, 0, 0.2)"
          backgroundColor={colors.greenDark}
          color="white"
          className={styles.fullBanner}
        >
          {bannerText}
        </Pane>
      )}
    </Pane>
  );
};

export const SparkCard = ({
  spark,
  isRegistered,
  onCancelReservation,
  onRegister,
  ...props
}: SparkCardProps) => {
  const store = useStoreData();
  const { colors } = useGFTheme();
  const navigate = useNavigate();
  const [signUpDialogVisible, setSignUpDialogVisible] = useState(false);
  const [cancelConfirmationVisible, setCancelConfirmationVisible] =
    useState(false);
  const sparkFull = useMemo(() => isSparkFull(spark), [spark]);
  const bannerText = useMemo(
    () => getFullBannerText(sparkFull, store),
    [sparkFull, store]
  );

  return (
    <NewCard
      borderWidth="0"
      padding={0}
      overflow="hidden"
      display="flex"
      marginBottom="2rem"
      {...props}
    >
      <SparkImage
        src={spark.primary_image!}
        sparkId={spark.id}
        isRegistered={isRegistered}
        bannerText={bannerText}
        className={styles.bigImage}
      />
      <Pane flex={1} className={styles.cardContent}>
        <Pane display="flex" gap="0.5rem" flex={1}>
          <SparkImage
            src={spark.primary_image!}
            sparkId={spark.id}
            isRegistered={isRegistered}
            bannerText={bannerText}
            className={styles.smallImage}
          />
          <Pane>
            <H3
              textAlign="left"
              flex={1}
              marginBottom="0.5rem"
              cursor="pointer"
              onClick={() => navigate(`/spark/atlanta/${spark.id}`)}
              selectors={{
                "&:hover": {
                  color: colors.grayPrimary,
                },
              }}
            >
              {spark.title}
            </H3>

            <Paragraph size={300} marginBottom="0.25rem">
              {getDateTimeRangeString(spark.starts_at, spark.ends_at)}
            </Paragraph>

            <Pane className={styles.bigScreenInfo}>
              <Paragraph size={300} marginBottom="0.35rem">
                Ages: {getAgeRangeString(spark.min_age, spark.max_age)}
              </Paragraph>
              <Paragraph
                size={300}
                marginBottom="0.35rem"
                display="flex"
                alignItems="flex-start"
                gap="0.25rem"
              >
                <Icon icon={MapMarkerIcon} marginTop="3px" /> {spark.location}
              </Paragraph>
              <Pane display="flex" gap="0.5rem">
                {spark.capacity_women !== null && (
                  <CapacityTag
                    current={spark.registrationSummary.women}
                    capacity={spark.capacity_women}
                    highlightAlmostFull={
                      !isRegistered && store.user?.gender === "woman"
                    }
                    label={"Women Spots Left"}
                  />
                )}
                {spark.capacity_men !== null && (
                  <CapacityTag
                    current={spark.registrationSummary.men}
                    capacity={spark.capacity_men}
                    highlightAlmostFull={
                      !isRegistered && store.user?.gender === "man"
                    }
                    label={"Men Spots Left"}
                  />
                )}
                {spark.capacity_men === null &&
                  spark.capacity_women === null && (
                    <CapacityTag
                      current={
                        spark.registrationSummary.men +
                        spark.registrationSummary.women +
                        spark.registrationSummary.other
                      }
                      capacity={spark.capacity}
                      highlightAlmostFull={!isRegistered}
                      label={"Spots Left"}
                    />
                  )}
              </Pane>
            </Pane>
          </Pane>
        </Pane>
        <Pane marginTop="0.5rem">
          <Pane className={styles.smallScreenInfo}>
            <Paragraph size={300} marginBottom="0.35rem">
              Ages: {getAgeRangeString(spark.min_age, spark.max_age)}
            </Paragraph>
            <Paragraph
              size={300}
              marginBottom="0.35rem"
              display="flex"
              alignItems="flex-start"
              gap="0.25rem"
            >
              <Icon icon={MapMarkerIcon} marginTop="3px" /> {spark.location}
            </Paragraph>
            <Pane display="flex" gap="0.5rem">
              {spark.capacity_women !== null && (
                <CapacityTag
                  current={spark.registrationSummary.women}
                  capacity={spark.capacity_women}
                  highlightAlmostFull={
                    !isRegistered && store.user?.gender === "woman"
                  }
                  label={"Women Spots Left"}
                />
              )}
              {spark.capacity_men !== null && (
                <CapacityTag
                  current={spark.registrationSummary.men}
                  capacity={spark.capacity_men}
                  highlightAlmostFull={
                    !isRegistered && store.user?.gender === "man"
                  }
                  label={"Men Spots Left"}
                />
              )}
              {spark.capacity_men === null && spark.capacity_women === null && (
                <CapacityTag
                  current={
                    spark.registrationSummary.men +
                    spark.registrationSummary.women +
                    spark.registrationSummary.other
                  }
                  capacity={spark.capacity}
                  highlightAlmostFull={!isRegistered}
                  label={"Spots Left"}
                />
              )}
            </Pane>
          </Pane>
          <Pane display="flex" marginTop="0.5rem" className={styles.buttons}>
            {isRegistered ? (
              <>
                <Button
                  size="small"
                  flex={1}
                  maxWidth="14rem"
                  selectors={{
                    "&:hover": {
                      backgroundColor: colors.neutralLight,
                    },
                    "&": {
                      color: "black",
                    },
                  }}
                  onClick={() => navigate(`/spark/atlanta/${spark.id}`)}
                >
                  Details
                </Button>
                {/* <Button
                  size="small"
                  appearance="primary"
                  flex={1}
                  maxWidth="14rem"
                  backgroundColor={colors.redPrimary}
                  selectors={{
                    "&:hover": {
                      backgroundColor: colors.redDark,
                      color: "white",
                    },
                    "&": {
                      color: "black",
                    },
                  }}
                  onClick={() => setCancelConfirmationVisible(true)}
                >
                  Cancel Registration
                </Button> */}
              </>
            ) : (
              <>
                <Button
                  size="small"
                  flex={1}
                  maxWidth="14rem"
                  selectors={{
                    "&:hover": {
                      backgroundColor: colors.neutralLight,
                    },
                    "&": {
                      color: "black",
                    },
                  }}
                  onClick={() => navigate(`/spark/atlanta/${spark.id}`)}
                >
                  Details
                </Button>
                {isLoggedIn(store) && (
                  <Button
                    size="small"
                    appearance="primary"
                    flex={1}
                    maxWidth="14rem"
                    backgroundColor={colors.greenLight}
                    selectors={{
                      "&:hover": {
                        backgroundColor: colors.greenPrimary,
                      },
                      "&": {
                        color: "black",
                      },
                    }}
                    onClick={() => setSignUpDialogVisible(true)}
                  >
                    SIGN UP
                  </Button>
                )}
              </>
            )}
          </Pane>
        </Pane>
      </Pane>
      <CancelEventRegistrationConfirmationDialog
        spark={spark}
        isShown={cancelConfirmationVisible}
        onCloseComplete={() => setCancelConfirmationVisible(false)}
        onCancelReservation={onCancelReservation}
      />
      <SparkRegisterDialog
        spark={spark}
        isShown={signUpDialogVisible}
        onCloseComplete={() => setSignUpDialogVisible(false)}
        onRegister={() => {
          setSignUpDialogVisible(false);
          onRegister();
        }}
      />
    </NewCard>
  );
};
