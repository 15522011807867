import { Pane, Button, Paragraph } from "evergreen-ui";
import { useCallback } from "react";
import { store } from "../../../lib/store/store";
import { ActionType } from "../../../lib/store/storeActions";
import { useNavigate } from "../../../lib/useNavigate";
import { useOutletContext } from "react-router-dom";
import { ProgressScreen } from "../../../components/ProgressScreen/ProgressScreen";
import { AppContext } from "../../app/App/appTypes";
import { VerticalGroup } from "../../../components/VerticalGroup/VerticalGroup";
import { sparkScreenMap } from "./sparkScreens";
import { useGFTheme } from "../../../lib/themes";
import { ToggleButton } from "../../../components/ToggleButton/ToggleButton";

const atlantaNeighborhoods: Record<string, { includes: string[] }> = {
  Downtown: {
    includes: [
      "Centennial Park District",
      "Castleberry Hill",
      "Five Points",
      "South Downtown",
    ],
  },
  Midtown: {
    includes: ["Ansley Park", "Home Park", "Piedmont Park area"],
  },
  ["West Side"]: {
    includes: ["Atlantic Station", "West Highlands", "Blandtown"],
  },
  ["East Atlanta"]: {
    includes: [
      "Sweet Auburn",
      "Old Fourth Ward",
      "Inman Park",
      "Cabbagetown",
      "Reynoldstown",
      "East Atlanta Village (EAV)",
      "Kirkwood",
      "Edgewood",
      "Grant Park",
      "Ormewood Park",
    ],
  },
  Decatur: {
    includes: [],
  },
  ["West Atlanta"]: {
    includes: [
      "West End",
      "Westview",
      "Adair Park",
      "Ashview Heights",
      "Vine City",
      "English Avenue",
      "Mozley Park",
      "Castleberry Hill",
      "Bankhead",
    ],
  },
  ["North Atlanta"]: {
    includes: [
      "Buckhead",
      "Peachtree Hills",
      "Garden Hills",
      "Brookwood Hills",
      "Chastain Park",
    ],
  },
  Brookhaven: { includes: [] },
  ["North Druid Hills"]: { includes: [] },
  Vinings: { includes: [] },
  Smyrna: { includes: [] },
  ["Sandy Springs"]: { includes: [] },
};

export const SparkNeighborhoods = () => {
  const navigate = useNavigate();
  const { colors } = useGFTheme();
  const {
    storeData: { sparkRegistration },
  } = useOutletContext<AppContext>();
  const next = useCallback(async () => {
    navigate(`/spark/${sparkScreenMap["neighborhoods"].next}`);
  }, [navigate]);

  const toggle = useCallback(
    (val: string) => () => {
      const neighborhoods = sparkRegistration.neighborhoods || [];
      store.dispatch({
        type: ActionType.UpdateSparkRegistration,
        params: {
          neighborhoods: neighborhoods.includes(val)
            ? neighborhoods.filter((n) => n !== val)
            : [...neighborhoods, val],
        },
      });
    },
    [sparkRegistration.neighborhoods]
  );

  return (
    <ProgressScreen
      title="Where can you go?"
      progress={sparkScreenMap["neighborhoods"].progress}
      prev={`/spark/${sparkScreenMap["neighborhoods"].prev}`}
      canContinue={
        !!sparkRegistration.neighborhoods &&
        sparkRegistration.neighborhoods.length >= 1
      }
      titleAlign="center"
      onContinue={next}
    >
      <Pane
        display="flex"
        flexDirection="column"
        gap="0.75rem"
        alignItems="flex-end"
      >
        <Button
          size="small"
          onClick={() => {
            store.dispatch({
              type: ActionType.UpdateSparkRegistration,
              params: {
                neighborhoods:
                  sparkRegistration.neighborhoods?.length ===
                  Object.keys(atlantaNeighborhoods).length
                    ? []
                    : Object.keys(atlantaNeighborhoods),
              },
            });
          }}
        >
          {sparkRegistration.neighborhoods?.length ===
          Object.keys(atlantaNeighborhoods).length
            ? "Select None"
            : "Select All"}
        </Button>
        <VerticalGroup width="100%" marginBottom="1.5rem">
          {Object.keys(atlantaNeighborhoods).map((k) => (
            <ToggleButton
              width="100%"
              key={k}
              onClick={toggle(k)}
              isActive={sparkRegistration.neighborhoods?.includes(k)}
              flexDirection="column"
              minHeight="45px"
              height="auto"
              overflowX="hidden"
              style={{ textWrap: "wrap" }}
            >
              <Pane fontWeight={700}>{k}</Pane>
              {!!atlantaNeighborhoods[k].includes.length && (
                <Paragraph size={300} width="100%" color={colors.neutralDark}>
                  {atlantaNeighborhoods[k].includes.join(", ")}
                </Paragraph>
              )}
            </ToggleButton>
          ))}
        </VerticalGroup>
      </Pane>
    </ProgressScreen>
  );
};
