import {
  Button,
  Dialog,
  IconButton,
  Pane,
  Paragraph,
  SmallCrossIcon,
  SmallTickIcon,
  TrashIcon,
} from "evergreen-ui";
import heic2any from "heic2any";
import Cropper, {
  Area,
  MediaSize,
  Point,
  Size,
  getInitialCropFromCroppedAreaPercentages,
} from "react-easy-crop";
import { H2 } from "../../../components/Heading/Heading";
import { Loader } from "../../../components/Loader/Loader";
import {
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { PictureData, pictureMeta } from "./pictureMeta";
import { SelectPictureButton } from "./SelectPictureButton";

import styles from "./Pictures.module.css";
import { ProfilePictureKey } from "@greenflagdate/shared";
import { useGFTheme } from "../../../lib/themes";

const MIN_ZOOM = 1;
const MAX_ZOOM = 3;

interface PictureEditorProps {
  pictureType?: ProfilePictureKey;
  isShown: boolean;
  onSave: (data: PictureData) => void;
  onClose: () => void;
  onDelete: () => void;
  blob?: Blob;
  cropArea?: Area;
}

interface PictureEditorState {
  converting: boolean;
  openComplete: boolean;
  cropPoint?: Point;
  zoom?: number;
  blobOverride?: Blob | null;
  cropSize?: Size;
  mediaSize?: MediaSize;
}

const reducer: Reducer<PictureEditorState, Partial<PictureEditorState>> = (
  state,
  merge
) => ({ ...state, ...merge });

export const PictureEditor = ({
  pictureType: key,
  isShown,
  onSave,
  onClose,
  onDelete,
  blob,
  cropArea,
}: PictureEditorProps) => {
  const [
    {
      converting,
      openComplete,
      cropSize,
      mediaSize,
      blobOverride,
      cropPoint,
      zoom,
    },
    dispatch,
  ] = useReducer(reducer, {
    converting: false,
    openComplete: false,
  });
  const { colors } = useGFTheme();
  const currentCropArea = useRef<Area>({ x: 0, y: 0, width: 100, height: 100 });
  const finalImageSrc = useMemo(() => {
    if (blobOverride === null) {
      return null;
    }
    const calcBlob = blobOverride || blob;
    if (!calcBlob) {
      return null;
    }
    return URL.createObjectURL(calcBlob);
  }, [blob, blobOverride]);
  const page = finalImageSrc ? "crop" : converting ? "loading" : "select";
  const close = useCallback(() => {
    dispatch({
      openComplete: false,
      converting: false,
      blobOverride: undefined,
      cropPoint: undefined,
      zoom: undefined,
    });

    onClose();
  }, [onClose]);

  const del = useCallback(() => {
    dispatch({
      converting: false,
      cropPoint: undefined,
      zoom: undefined,
      blobOverride: null,
      cropSize: undefined,
      mediaSize: undefined,
    });

    onDelete();
  }, [onDelete]);

  useEffect(() => {
    if (cropSize !== undefined && mediaSize !== undefined) {
      const { crop, zoom } = getInitialCropFromCroppedAreaPercentages(
        cropArea || { height: 100, width: 100, x: 0, y: 0 },
        mediaSize,
        0,
        cropSize,
        MIN_ZOOM,
        MAX_ZOOM
      );
      dispatch({
        cropPoint: crop,
        zoom,
      });
    }
  }, [cropArea, cropSize, mediaSize]);
  return (
    <Dialog
      isShown={isShown}
      onCloseComplete={close}
      onOpenComplete={() => dispatch({ openComplete: true })}
      hasHeader={false}
      topOffset={"2rem"}
      containerProps={{
        className: styles.pictureUpload,
      }}
      contentContainerProps={{
        padding: "1.5rem",
        selectors: {
          "& > div": {
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            overflowY: "hidden",
          },
        },
      }}
      footer={
        <Pane gap="0.5rem" display="flex" flexDirection="column" width="100%">
          {page === "select" && (
            <SelectPictureButton
              onSelect={(file) => {
                if (file.type === "image/heic") {
                  dispatch({ converting: true });
                  heic2any({ blob: file, toType: "image/jpeg" }).then(
                    (convertedBlob) => {
                      const convertedFile = Array.isArray(convertedBlob)
                        ? convertedBlob[0]
                        : convertedBlob;
                      dispatch({
                        blobOverride: convertedFile,
                      });
                    }
                  );
                } else {
                  dispatch({
                    blobOverride: file,
                  });
                }
              }}
            />
          )}
          {page === "loading" && (
            <Button appearance="primary">
              <Loader color="blue" size={20} />
            </Button>
          )}
          {page === "crop" && (
            <Button
              appearance="primary"
              onClick={() =>
                onSave({
                  cropArea: currentCropArea.current,
                  imageSrc: finalImageSrc!,
                  blob: (blobOverride || blob)!,
                })
              }
            >
              Save
            </Button>
          )}
          <Button appearance="minimal" onClick={close}>
            Cancel
          </Button>
        </Pane>
      }
    >
      {page === "loading" && <Loader />}
      {page === "select" && key && (
        <>
          <Pane
            flex={1}
            display="flex"
            flexDirection="column"
            overflow="hidden"
            gap="0.5rem"
          >
            {pictureMeta[key].images.map((i) => (
              <Pane
                flex={1}
                maxHeight="calc(50% - 0.25rem)"
                width="auto"
                display="flex"
                justifyContent="center"
                key={i.src}
              >
                <Pane
                  backgroundImage={`url(${i.src})`}
                  height="100%"
                  className={styles.imagePlaceholder}
                  backgroundSize="cover"
                  position="relative"
                >
                  <IconButton
                    is="div"
                    icon={
                      i.type === "good" ? (
                        <SmallTickIcon color={colors.greenDark} />
                      ) : (
                        <SmallCrossIcon color={colors.redDark} />
                      )
                    }
                    position="absolute"
                    width="1.5rem"
                    height="1.5rem"
                    border="none"
                    background={
                      i.type === "good" ? colors.greenLight : colors.redPrimary
                    }
                    top="7%"
                    right="7%"
                    boxShadow="0px 2px 4px rgba(0, 0, 0, 0.25)"
                    pointerEvents="none"
                  />
                </Pane>
              </Pane>
            ))}
          </Pane>
          <Pane>
            <H2 marginY="1.5rem">{pictureMeta[key].title}</H2>
            <Paragraph size={500} marginBottom="1.5rem">
              {!!key && pictureMeta[key].description}
            </Paragraph>
          </Pane>
        </>
      )}
      {page === "crop" && (
        <Pane position="relative" width="100%" paddingBottom="100%">
          <IconButton
            icon={TrashIcon}
            intent="danger"
            zIndex={1}
            position="absolute"
            right={5}
            top={5}
            onClick={del}
          />
          {openComplete && (
            <Cropper
              image={finalImageSrc!}
              crop={cropPoint || { x: 0, y: 0 }}
              zoom={zoom}
              aspect={1}
              onCropChange={(cropPoint) => dispatch({ cropPoint })}
              onCropComplete={(cropArea) =>
                (currentCropArea.current = cropArea)
              }
              onZoomChange={(zoom) => dispatch({ zoom })}
              setCropSize={(cropSize) => dispatch({ cropSize })}
              setMediaSize={(mediaSize) => dispatch({ mediaSize })}
              minZoom={MIN_ZOOM}
              maxZoom={MAX_ZOOM}
            />
          )}
        </Pane>
      )}
    </Dialog>
  );
};
