import { PropsWithChildren, ReactNode } from "react";

import { Alert, Button, Pane } from "evergreen-ui";
import { useGFTheme } from "../../lib/themes";
import { useBackgroundColor } from "../../lib/useBackgroundColor";
import { H2 } from "../Heading/Heading";
import { Loader } from "../Loader/Loader";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { useNavigate } from "../../lib/useNavigate";

interface ProgressScreenProps {
  title: string | ReactNode;
  progress: number;
  prev?: string;
  canContinue: boolean;
  onContinue: () => void;
  titleAlign?: "left" | "center";
  isLoading?: boolean;
  error?: string;
  hideProgressBar?: boolean;
  hideTitle?: boolean;
  continueButtonLabel?: string;
}

export const ProgressScreen = ({
  title,
  titleAlign,
  progress,
  prev,
  canContinue,
  onContinue,
  isLoading,
  error,
  hideProgressBar,
  hideTitle,
  continueButtonLabel,
  children,
}: PropsWithChildren<ProgressScreenProps>) => {
  const { colors } = useGFTheme();
  useBackgroundColor(colors.neutralLight);
  const navigate = useNavigate();
  return (
    <Pane
      maxWidth="35rem"
      padding="2rem"
      marginX="auto"
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      position="relative"
      paddingBottom="7.625rem"
    >
      {!hideProgressBar && <ProgressBar progress={progress} />}

      {!hideTitle && (
        <H2 marginTop="2.5rem" textAlign={titleAlign}>
          {title}
        </H2>
      )}

      {!!error && (
        <Alert marginTop="2rem" intent="danger" title={error.toString()} />
      )}
      <Pane flex={1} marginTop="1.625rem" overflowY="auto" padding="0.75rem">
        {children}
      </Pane>

      <Pane
        paddingTop="1rem"
        position="fixed"
        bottom={0}
        left={0}
        width="100%"
        backgroundColor={colors.neutralLight}
        boxShadow="0px 4px 5px 4px rgba(0,0,0,0.3)"
      >
        <Pane
          display="flex"
          flexDirection="column"
          maxWidth="31rem"
          marginX="auto"
        >
          <Button
            appearance="primary"
            disabled={!canContinue}
            onClick={onContinue}
            pointerEvents={isLoading ? "none" : "auto"}
          >
            {isLoading ? (
              <Loader color="blue" size={32} />
            ) : (
              continueButtonLabel || "Continue"
            )}
          </Button>
          {prev && (
            <Button
              appearance="minimal"
              onClick={() => navigate(prev, "pop")}
              marginTop="1rem"
              pointerEvents={isLoading ? "none" : "auto"}
            >
              Back
            </Button>
          )}
        </Pane>
      </Pane>
    </Pane>
  );
};
