import { SparkWithRegistrationSummary } from "@greenflagdate/shared";
import { useReq } from "@larner.dev/use-req";
import { Button, Dialog, DialogProps, Pane, Paragraph } from "evergreen-ui";
import { apiReq } from "../../lib/apiReq";
import { Alert } from "../Alert/Alert";
import { store, useStoreData } from "../../lib/store/store";
import { isLoggedIn } from "../../lib/isLoggedIn";
import { ActionType } from "../../lib/store/storeActions";
import { LinkButton } from "../LinkButton/LinkButton";
import { UserRequiredInfoForm } from "../UserRequiredInfoForm/UserRequiredInfoForm";
import { getSparkErrorMessage } from "../../lib/sparkHelpers";
import { PaymentForm, PaymentFormHandle } from "../PaymentForm/PaymentForm";
import { useCallback, useRef, useState } from "react";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

interface SparkRegisterDialogProps extends DialogProps {
  spark: SparkWithRegistrationSummary;
  onRegister: () => void;
}

export const SparkRegisterDialog = ({
  spark,
  onRegister,
  ...props
}: SparkRegisterDialogProps) => {
  const [req, reqState] = useReq(apiReq);
  const storeData = useStoreData();
  const paymentFormRef = useRef<PaymentFormHandle>(null);
  const [paymentFormLoading, setPaymentFormLoading] = useState(false);
  const doRegister = useCallback(async () => {
    const response = await req.post("/spark/register", {
      spark_id: spark.id,
    });
    if (response.success) {
      onRegister();
    }
  }, [onRegister, req, spark.id]);

  if (!isLoggedIn(storeData)) {
    return null;
  }
  const isFree = spark.price === 0;
  return (
    <Dialog title={spark.title} hasFooter={false} {...props}>
      {({ close }) => (
        <>
          <Pane display="flex" flexDirection="column" alignItems="flex-end">
            <Pane
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Pane fontWeight="bold" color="#A5A5A5">
                Hi, {storeData.user?.first_name}!
              </Pane>
              <Pane fontSize="0.75rem">
                Not {storeData.user?.first_name}?&nbsp;
                <LinkButton
                  fontSize="0.75rem"
                  onClick={() => {
                    store.dispatch({
                      type: ActionType.LogOut,
                      params: {},
                    });
                    close();
                  }}
                >
                  Log out
                </LinkButton>
              </Pane>
            </Pane>
            {!storeData.user?.first_name ||
            !storeData.user?.birth_date ||
            !storeData.user?.gender ||
            !storeData.user?.gender_preference ||
            (!storeData.user?.phone_number_to_validate &&
              !storeData.user?.phone_number) ? (
              <>
                <Paragraph size={500} marginTop="1rem">
                  Please fill in the following information to sign up for this
                  event.
                </Paragraph>
                <UserRequiredInfoForm
                  width="100%"
                  marginY="1rem"
                  buttonLabel="Continue"
                />
              </>
            ) : (
              <>
                {reqState.error && (
                  <Alert
                    style={{ width: "100%", marginTop: "1rem" }}
                    title={getSparkErrorMessage(
                      reqState.error.code,
                      storeData.user!,
                      spark,
                      reqState.error.params
                    )}
                    intent="danger"
                  />
                )}
                <Pane
                  fontSize="0.75rem"
                  paddingY="1rem"
                  paddingX="0.25rem"
                  display="flex"
                  flexDirection="column"
                  gap="0.5rem"
                  width="100%"
                >
                  <Pane display="flex" justifyContent="space-between">
                    <Pane>{spark.title}</Pane>
                    <Pane>{currencyFormatter.format(spark.price)}</Pane>
                  </Pane>
                  <Pane display="flex" justifyContent="space-between">
                    <Pane>Tax</Pane>
                    <Pane>{currencyFormatter.format(spark.price * 0.089)}</Pane>
                  </Pane>
                  <Pane
                    display="flex"
                    justifyContent="space-between"
                    borderTop="1px solid black"
                  >
                    <Pane>Total</Pane>
                    <Pane>{currencyFormatter.format(spark.price * 1.089)}</Pane>
                  </Pane>
                </Pane>
                {!isFree && (
                  <PaymentForm
                    ref={paymentFormRef}
                    width="100%"
                    onLoadingStatusChange={setPaymentFormLoading}
                    onSubmit={async () => {
                      await doRegister();
                    }}
                  />
                )}

                <Button
                  appearance="primary"
                  marginTop="2rem"
                  marginBottom="1rem"
                  maxWidth="20rem"
                  isLoading={paymentFormLoading || reqState.loading}
                  marginX="auto"
                  width="100%"
                  onClick={() =>
                    isFree ? doRegister() : paymentFormRef.current?.submitForm()
                  }
                >
                  REGISTER
                </Button>
              </>
            )}
          </Pane>
        </>
      )}
    </Dialog>
  );
};
