import { Pane } from "evergreen-ui";
import { LoginCard } from "../../../components/LoginCard/LoginCard";
import { useSearchParams } from "react-router-dom";

export const SignIn = () => {
  const [searchParams] = useSearchParams();
  return (
    <Pane padding="2rem">
      <LoginCard
        width="100%"
        maxWidth="35rem"
        marginX="auto"
        heading="SIGN IN"
        successRedirect={searchParams.get("r") || "/events"}
      />
    </Pane>
  );
};
