import { Button, Dialog, Pane, Paragraph } from "evergreen-ui";
import { useCallback, useEffect, useState } from "react";
import { VerificationCode } from "../../../components/VerificationCode/VerificationCode";
import { store } from "../../../lib/store/store";
import {
  PublicCredentialRecord,
  UserAttributes,
  UserWithMediaAndGroups,
} from "@greenflagdate/shared";
import { ActionType } from "../../../lib/store/storeActions";
import { useReq } from "@larner.dev/use-req";
import { apiReq } from "../../../lib/apiReq";
import { useNavigate, useOutletContext } from "react-router-dom";
import { AppContext } from "../../app/App/appTypes";
import { ProgressScreen } from "../../../components/ProgressScreen/ProgressScreen";
import { sparkScreenMap } from "./sparkScreens";
import { LinkButton } from "../../../components/LinkButton/LinkButton";
import isEmail from "validator/lib/isEmail";

export const SparkEmailVerify = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const { storeData } = useOutletContext<AppContext>();
  const [showEditEmail, setShowEditEmail] = useState(false);
  const [resendLabel, setResendLabel] = useState("Re-send Code");
  const [request, requestState] = useReq<{
    token: string;
    user: UserWithMediaAndGroups;
  }>(apiReq);
  const [updateUserRequest] = useReq<UserWithMediaAndGroups>(apiReq);
  const [resendRequest] = useReq<PublicCredentialRecord>(apiReq);
  const next = useCallback(
    async (c: string) => {
      if (c.length === 6) {
        const response = await request.post("/user/verify?source=spark", {
          token: `${c}.${storeData.auth.emailCredentialId}`,
        });
        if (response.success) {
          store.dispatch({
            type: ActionType.Login,
            params: response.result,
          });
          const attributes: Partial<UserAttributes> = {};
          if ("birthDate" in storeData.sparkRegistration) {
            attributes.birth_date = storeData.sparkRegistration.birthDate;
          }
          if ("gender" in storeData.sparkRegistration) {
            attributes.gender = storeData.sparkRegistration.gender;
          }
          if ("genderPreference" in storeData.sparkRegistration) {
            attributes.gender_preference =
              storeData.sparkRegistration.genderPreference;
          }
          if ("firstName" in storeData.sparkRegistration) {
            attributes.first_name = storeData.sparkRegistration.firstName;
          }
          if (storeData.sparkRegistration.ageRange) {
            attributes.min_age_preference =
              storeData.sparkRegistration.ageRange[0];
            attributes.max_age_preference =
              storeData.sparkRegistration.ageRange[1];
          }
          const userResponse = await updateUserRequest.put(
            "/user/attributes",
            attributes
          );
          if (userResponse.success) {
            store.dispatch({
              type: ActionType.UpdateUser,
              params: { user: userResponse.result },
            });
          }

          navigate(`/spark/${sparkScreenMap["email-verify"].next}`);
        }
      }
    },
    [
      navigate,
      request,
      storeData.auth.emailCredentialId,
      storeData.sparkRegistration,
      updateUserRequest,
    ]
  );
  const change = useCallback(
    (c: string) => {
      if (c.length === 6) {
        next(c);
      }
      setCode(c);
    },
    [next]
  );
  useEffect(() => {
    if (!storeData.auth.emailCredentialId || !storeData.auth.email) {
      navigate(`/spark/${sparkScreenMap["email-verify"].prev}`);
    }
  }, [navigate, storeData.auth.email, storeData.auth.emailCredentialId]);
  return (
    <ProgressScreen
      title="Enter the code we emailed to you"
      progress={sparkScreenMap["email-verify"].progress}
      prev={`/spark/${sparkScreenMap["email-verify"].prev}`}
      canContinue={code.length === 6}
      onContinue={() => {
        next(code);
      }}
      isLoading={requestState.loading}
      error={requestState.error?.code}
    >
      <Pane display="flex" marginBottom="1.625rem" alignItems="center">
        <Paragraph size={500} flex={1}>
          Sent to {storeData.auth.email || "unknown"}
        </Paragraph>
        <Button
          appearance="minimal"
          backgroundColor="transparent"
          selectors={{
            "&:hover": {
              backgroundColor: "transparent",
              textDecoration: "underline",
            },
          }}
          onClick={() => setShowEditEmail(true)}
        >
          Edit
        </Button>
      </Pane>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          next(code);
        }}
      >
        <VerificationCode value={code} onChange={change} length={6} />
      </form>
      {isEmail(storeData.auth.email || "") && (
        <LinkButton
          marginTop="1rem"
          disabled={resendLabel !== "Re-send Code"}
          onClick={async (e) => {
            if (resendLabel !== "Re-send Code") {
              return;
            }
            e.preventDefault();
            setResendLabel("Sending...");
            const response = await resendRequest.post("/user", {
              email: storeData.auth.email,
            });
            if (response.success) {
              store.dispatch({
                type: ActionType.UpdateAuth,
                params: {
                  emailCredentialId: response.result.id,
                },
              });
              setResendLabel("A new code was sent");
              setTimeout(() => setResendLabel("Re-send Code"), 10000);
            }
          }}
          textDecoration={resendLabel === "Re-send Code" ? "underline" : "none"}
        >
          {resendLabel}
        </LinkButton>
      )}

      <Dialog
        isShown={showEditEmail}
        title="What's your email?"
        onCloseComplete={() => setShowEditEmail(false)}
        hasFooter={false}
        containerProps={{
          height: "100%",
        }}
        contentContainerProps={{
          height: "100%",
          selectors: {
            "& > div": {
              height: "100%",
              display: "flex",
              overflow: "hidden",
              flexDirection: "column",
              gap: "0.75rem",
            },
          },
        }}
      ></Dialog>
    </ProgressScreen>
  );
};
