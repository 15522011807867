import {
  Alert,
  Badge,
  BadgeProps,
  Button,
  FormField,
  IconButton,
  Pane,
  Paragraph,
  PlusIcon,
  TextInput,
} from "evergreen-ui";
import { H2, H3 } from "../../../components/Heading/Heading";
import {
  useSparkActivityData,
  useSparkNeighborhoodData,
} from "../../../lib/sparkHelpers";
import { Loader } from "../../../components/Loader/Loader";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLoggedIn } from "../../../lib/useLoggedIn";
import { useStoreData } from "../../../lib/store/store";
import { apiReq } from "../../../lib/apiReq";
import { useReq } from "@larner.dev/use-req";
import {
  getMondayForSpark,
  SparkPreference,
  etZone,
} from "@greenflagdate/shared";
import { NewCard } from "../../../components/Card/Card";
import { useGFTheme } from "../../../lib/themes";
import classNames from "classnames";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";
import { useSparkDates } from "../../../lib/useSparkDates";
import { CancelSparkConfirmationDialog } from "../../../components/CancelSparkConfirmationDialog/CancelSparkConfirmationDialog";
import { LinkButton } from "../../../components/LinkButton/LinkButton";
import { useNavigate } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(timezone);

const OptionBadge = ({ children, ...props }: BadgeProps) => {
  const { colors } = useGFTheme();
  return (
    <Badge
      textTransform="none"
      fontWeight={400}
      marginX="0.5rem"
      marginY="0.375rem"
      cursor="pointer"
      selectors={{
        "&": {
          color: colors.greenDark,
        },
        "&.selected": {
          backgroundColor: colors.greenLight,
          color: "white",
        },
        "&:hover": {
          opacity: 0.8,
        },
      }}
      {...props}
    >
      {children}
    </Badge>
  );
};

interface Errors {
  availableDays?: string;
  neighborhoods?: string;
  activities?: string;
}

export const SparkPreferences = () => {
  const { colors } = useGFTheme();
  const storeData = useStoreData();
  const navigate = useNavigate();
  useLoggedIn(storeData);
  const { loading: sparkActivitiesLoading, sparkActivities } =
    useSparkActivityData();
  const { loading: sparkNeighborhoodsLoading, sparkNeighborhoods } =
    useSparkNeighborhoodData();

  const [savePrefReq, savePrefReqState] = useReq<SparkPreference>(apiReq);
  const [sparkSignupReq, sparkSignupReqState] = useReq<SparkPreference>(apiReq);
  const [latestPrefReq, latestPrefReqState] = useReq<SparkPreference | null>(
    apiReq
  );
  const [customActivity, setCustomActivity] = useState("");
  const [cancelConfirmationVisible, setCancelConfirmationVisible] =
    useState(false);
  const [currentPreferences, setCurrentPreferences] =
    useState<Partial<SparkPreference> | null>(null);
  const [errors, setErrors] = useState<Errors>({});
  const currentKey = useMemo(
    () => getMondayForSpark().format("YYYY-MM-DD"),
    []
  );
  const availableDays = useMemo(
    () => currentPreferences?.available_days || [],
    [currentPreferences?.available_days]
  );
  const neighborhoods = useMemo(
    () => currentPreferences?.neighborhoods || [],
    [currentPreferences?.neighborhoods]
  );
  const activities = useMemo(
    () => currentPreferences?.activities || [],
    [currentPreferences?.activities]
  );
  const customActivities = useMemo(
    () =>
      (currentPreferences?.activities || []).filter(
        (activity) =>
          !sparkActivities.some(
            (cmsActivity) => cmsActivity.attributes.name === activity
          )
      ),
    [currentPreferences?.activities, sparkActivities]
  );

  const sparkDates = useSparkDates();
  useEffect(() => {
    latestPrefReq.get("/spark/preferences/latest").then((res) => {
      if (res.success) {
        setCurrentPreferences(res.result);
      } else {
        setCurrentPreferences(null);
      }
    });
  }, [latestPrefReq]);
  const updatePreferences = useCallback(
    async (update: Partial<SparkPreference>) => {
      const newPreferences = { ...currentPreferences, ...update };
      setCurrentPreferences((existing) => ({ ...existing, ...update }));
      const newErrors = { ...errors };
      if (update.available_days && update.available_days.length) {
        delete newErrors.availableDays;
      }
      if (update.neighborhoods && update.neighborhoods.length) {
        delete newErrors.neighborhoods;
      }
      if (update.activities && update.activities.length) {
        delete newErrors.activities;
      }
      setErrors(newErrors);
      if (currentPreferences?.key === currentKey) {
        const res = await savePrefReq.put("/spark/preference", {
          neighborhoods: newPreferences?.neighborhoods || [],
          availableDays: newPreferences?.available_days || [],
          activities: newPreferences?.activities || [],
          canLead: newPreferences?.can_lead || false,
        });
        if (res.success) {
          setCurrentPreferences(res.result);
        }
      }
    },
    [currentKey, currentPreferences, errors, savePrefReq]
  );
  const sparkSignup = useCallback(async () => {
    const errors: Errors = {};
    const availableDays = currentPreferences?.available_days || [];
    const neighborhoods = currentPreferences?.neighborhoods || [];
    const activities = currentPreferences?.activities || [];

    if (!availableDays.length) {
      errors.availableDays =
        "You must pick at least one day that you're available";
    }
    if (!neighborhoods.length) {
      errors.neighborhoods =
        "You must pick at least one neighborhood that you can travel to";
    }
    if (!activities.length) {
      errors.activities =
        "You must pick at least one activity that you're interested in";
    }
    setErrors(errors);
    if (Object.keys(errors).length) {
      return;
    }

    const res = await sparkSignupReq.put("/spark/preference", {
      neighborhoods: currentPreferences?.neighborhoods || [],
      availableDays: currentPreferences?.available_days || [],
      activities: currentPreferences?.activities || [],
      canLead: currentPreferences?.can_lead || false,
    });
    if (res.success) {
      setCurrentPreferences(res.result);
    }
  }, [
    currentPreferences?.activities,
    currentPreferences?.available_days,
    currentPreferences?.can_lead,
    currentPreferences?.neighborhoods,
    sparkSignupReq,
  ]);
  return (
    <Pane
      maxWidth="40rem"
      marginX="auto"
      marginTop="1.5rem"
      paddingX="1.5rem"
      paddingBottom="2rem"
    >
      <Pane display="flex">
        <H2 flex={1}>Spark Registration</H2>
      </Pane>
      {latestPrefReqState.loading ? (
        <Loader />
      ) : (
        <>
          {(!currentPreferences || currentPreferences.key !== currentKey) && (
            <Alert
              title="You aren't signed up for any sparks right now..."
              marginTop="1.5rem"
              selectors={{ "& h4": { fontSize: "1rem" } }}
            >
              <Button
                appearance="primary"
                size="small"
                marginTop="1rem"
                isLoading={sparkSignupReqState.loading}
                onClick={sparkSignup}
              >
                Sign me up for next week!
              </Button>
            </Alert>
          )}
          {currentPreferences && currentPreferences.key === currentKey && (
            <Alert
              title="You're signed up for next week!"
              marginTop="1.5rem"
              selectors={{ "& h4": { fontSize: "1rem" } }}
            >
              You can adjust your preferences below.
            </Alert>
          )}
          {!!savePrefReqState.error && (
            <Alert
              marginTop="1.5rem"
              title={
                savePrefReqState.error.code === "INVALID_AVAILABLE_DAYS" ? (
                  <>
                    <Pane>
                      You must select at least one day.
                    </Pane>
                    {/* <CancelSparkButton
                      marginTop="0.5rem"
                      size="small"
                      onCancelReservation={() => {
                        latestPrefReq
                          .get("/spark/preferences/latest")
                          .then((res) => {
                            if (res.success) {
                              setCurrentPreferences(res.result);
                            } else {
                              setCurrentPreferences(null);
                            }
                            savePrefReqState.clearError();
                          });
                      }}
                    /> */}
                  </>
                ) : (
                  savePrefReqState.error.message
                )
              }
              intent="danger"
            />
          )}
          <Pane display="flex" marginTop="1.5rem">
            <H3 flex={1}>Availability</H3>
            <LinkButton
              onClick={() =>
                updatePreferences({
                  available_days:
                    availableDays.length === 11
                      ? []
                      : [
                        ...Array.from({ length: 5 }, (_, i) => i).map(
                          (dayNumber) =>
                            sparkDates[0]
                              .add(dayNumber, "days")
                              .set("hour", 19)
                              .toISOString()
                        ),
                        ...Array.from({ length: 2 }, (_, i) => i).map(
                          (dayNumber) =>
                            sparkDates[0]
                              .add(dayNumber + 5, "days")
                              .set("hour", 11)
                              .toISOString()
                        ),
                        ...Array.from({ length: 2 }, (_, i) => i).map(
                          (dayNumber) =>
                            sparkDates[0]
                              .add(dayNumber + 5, "days")
                              .set("hour", 18)
                              .toISOString()
                        ),
                        ...Array.from({ length: 2 }, (_, i) => i).map(
                          (dayNumber) =>
                            sparkDates[0]
                              .add(dayNumber + 5, "days")
                              .set("hour", 19)
                              .toISOString()
                        ),
                      ],
                })
              }
            >
              {availableDays.length === 11 ? "Select None" : "Select All"}
            </LinkButton>
          </Pane>
          <FormField validationMessage={errors.availableDays}>
            <NewCard
              padding={0}
              borderWidth="1px"
              borderColor={
                errors.availableDays ? colors.redPrimary : colors.neutralMedium
              }
              marginTop="1.25rem"
            >
              {Array.from({ length: 7 }, (_, i) => i).map((dayNumber) => {
                const day = sparkDates[0].add(dayNumber, "days");
                return (
                  <Pane
                    display="flex"
                    selectors={{
                      "&:last-child > div:first-child": {
                        borderBottom: "none",
                      },
                    }}
                  >
                    <Pane
                      color="#ADADAD"
                      textAlign="center"
                      borderBottom="1px solid #ADADAD"
                      paddingY="0.5rem"
                      fontSize="0.65rem"
                      width="2.875rem"
                      borderRight={`2px solid ${colors.neutralMedium}`}
                    >
                      {day.format("ddd D").toUpperCase()}
                    </Pane>
                    <Pane display="flex" flex={1}>
                      {dayNumber < 5 && (
                        <>
                          <Pane flex={1} marginX="0.875rem" />
                          <Pane flex={1} marginX="0.875rem" />
                          <OptionBadge
                            flex={1}
                            className={classNames({
                              selected: availableDays.some((d) => {
                                const checkDay = dayjs(d).tz(etZone);
                                return (
                                  checkDay.day() === (dayNumber + 1) % 7 &&
                                  checkDay.hour() === 19
                                );
                              }),
                            })}
                            onClick={() => {
                              const dayTime = day.set("hour", 19).toISOString();
                              updatePreferences({
                                available_days: availableDays.includes(dayTime)
                                  ? availableDays.filter((d) => d !== dayTime)
                                  : [...availableDays, dayTime],
                              });
                            }}
                          >
                            7pm
                          </OptionBadge>
                        </>
                      )}
                      {dayNumber >= 5 && (
                        <>
                          <OptionBadge
                            flex={1}
                            className={classNames({
                              selected: availableDays.some((d) => {
                                const checkDay = dayjs(d).tz(etZone);
                                return (
                                  checkDay.day() === (dayNumber + 1) % 7 &&
                                  checkDay.hour() === 11
                                );
                              }),
                            })}
                            onClick={() => {
                              const dayTime = day.set("hour", 11).toISOString();
                              updatePreferences({
                                available_days: availableDays.includes(dayTime)
                                  ? availableDays.filter((d) => d !== dayTime)
                                  : [...availableDays, dayTime],
                              });
                            }}
                          >
                            11am
                          </OptionBadge>
                          <OptionBadge
                            flex={1}
                            className={classNames({
                              selected: availableDays.some((d) => {
                                const checkDay = dayjs(d).tz(etZone);
                                return (
                                  checkDay.day() === (dayNumber + 1) % 7 &&
                                  checkDay.hour() === 18
                                );
                              }),
                            })}
                            onClick={() => {
                              const dayTime = day.set("hour", 18).toISOString();
                              updatePreferences({
                                available_days: availableDays.includes(dayTime)
                                  ? availableDays.filter((d) => d !== dayTime)
                                  : [...availableDays, dayTime],
                              });
                            }}
                          >
                            6pm
                          </OptionBadge>
                          <OptionBadge
                            flex={1}
                            className={classNames({
                              selected: availableDays.some((d) => {
                                const checkDay = dayjs(d).tz(etZone);
                                return (
                                  checkDay.day() === (dayNumber + 1) % 7 &&
                                  checkDay.hour() === 19
                                );
                              }),
                            })}
                            onClick={() => {
                              const dayTime = day.set("hour", 19).toISOString();
                              updatePreferences({
                                available_days: availableDays.includes(dayTime)
                                  ? availableDays.filter((d) => d !== dayTime)
                                  : [...availableDays, dayTime],
                              });
                            }}
                          >
                            7pm
                          </OptionBadge>
                        </>
                      )}
                    </Pane>
                  </Pane>
                );
              })}
            </NewCard>
          </FormField>
          <Pane display="flex" marginTop="1.5rem">
            <H3 flex={1}>Neighborhoods</H3>
            <LinkButton
              onClick={() =>
                updatePreferences({
                  neighborhoods:
                    neighborhoods.length === sparkNeighborhoods.length
                      ? []
                      : sparkNeighborhoods.map(
                        (neighborhood) => neighborhood.attributes.name
                      ),
                })
              }
            >
              {neighborhoods.length === sparkNeighborhoods.length
                ? "Select None"
                : "Select All"}
            </LinkButton>
          </Pane>
          {sparkNeighborhoodsLoading ? (
            <Loader />
          ) : (
            <FormField validationMessage={errors.neighborhoods}>
              <NewCard
                display="flex"
                flexWrap="wrap"
                padding="2px"
                borderWidth="1px"
                borderColor={
                  errors.neighborhoods
                    ? colors.redPrimary
                    : colors.neutralMedium
                }
                marginTop="1.25rem"
              >
                {sparkNeighborhoods.map((neighborhood) => {
                  return (
                    <OptionBadge
                      className={classNames({
                        selected: neighborhoods.includes(
                          neighborhood.attributes.name
                        ),
                      })}
                      onClick={() => {
                        updatePreferences({
                          neighborhoods: neighborhoods.includes(
                            neighborhood.attributes.name
                          )
                            ? neighborhoods.filter(
                              (n) => n !== neighborhood.attributes.name
                            )
                            : [...neighborhoods, neighborhood.attributes.name],
                        });
                      }}
                    >
                      {neighborhood.attributes.name}
                    </OptionBadge>
                  );
                })}
              </NewCard>
            </FormField>
          )}
          <Pane display="flex" marginTop="1.5rem">
            <H3 flex={1}>Activities</H3>
            <LinkButton
              onClick={() =>
                updatePreferences({
                  activities:
                    activities.length === sparkActivities.length
                      ? []
                      : sparkActivities.map(
                        (activity) => activity.attributes.name
                      ),
                })
              }
            >
              {activities.length === sparkActivities.length
                ? "Select None"
                : "Select All"}
            </LinkButton>
          </Pane>
          {sparkActivitiesLoading ? (
            <Loader />
          ) : (
            <>
              <Pane
                display="flex"
                marginTop="1.25rem"
                marginBottom="0.25rem"
                gap="0.5rem"
                alignItems="center"
              >
                <H3 fontSize="1rem">Basic Events</H3>
                <Paragraph size={300}>Admission: $15</Paragraph>
              </Pane>
              <FormField validationMessage={errors.activities}>
                <NewCard
                  display="flex"
                  flexWrap="wrap"
                  padding="2px"
                  borderWidth="1px"
                  borderColor={
                    errors.activities ? colors.redPrimary : colors.neutralMedium
                  }
                >
                  {sparkActivities
                    .filter((activity) => !activity.attributes.isPremium)
                    .map((activity) => (
                      <OptionBadge
                        className={classNames({
                          selected: (
                            currentPreferences?.activities || []
                          ).includes(activity.attributes.name),
                        })}
                        onClick={() => {
                          updatePreferences({
                            activities: activities.includes(
                              activity.attributes.name
                            )
                              ? activities.filter(
                                (n) => n !== activity.attributes.name
                              )
                              : [...activities, activity.attributes.name],
                          });
                        }}
                      >
                        {activity.attributes.name}
                      </OptionBadge>
                    ))}
                </NewCard>

                <Pane
                  display="flex"
                  marginTop="1.25rem"
                  marginBottom="0.25rem"
                  gap="0.5rem"
                  alignItems="center"
                >
                  <H3 fontSize="1rem">Premium Events</H3>
                  <Paragraph size={300}>Admission: $30</Paragraph>
                </Pane>
                <NewCard
                  display="flex"
                  flexWrap="wrap"
                  padding="2px"
                  borderWidth="1px"
                  borderColor={
                    errors.activities ? colors.redPrimary : colors.neutralMedium
                  }
                >
                  {sparkActivities
                    .filter((activity) => activity.attributes.isPremium)
                    .map((activity) => (
                      <OptionBadge
                        className={classNames({
                          selected: (
                            currentPreferences?.activities || []
                          ).includes(activity.attributes.name),
                        })}
                        onClick={() => {
                          updatePreferences({
                            activities: activities.includes(
                              activity.attributes.name
                            )
                              ? activities.filter(
                                (n) => n !== activity.attributes.name
                              )
                              : [...activities, activity.attributes.name],
                          });
                        }}
                      >
                        {activity.attributes.name}
                      </OptionBadge>
                    ))}
                </NewCard>
                {!!customActivities.length && (
                  <>
                    <Pane
                      display="flex"
                      marginTop="1.25rem"
                      marginBottom="0.25rem"
                      gap="0.5rem"
                      alignItems="center"
                    >
                      <H3 fontSize="1rem">Your Suggestions</H3>
                    </Pane>
                    <NewCard
                      display="flex"
                      flexWrap="wrap"
                      padding="2px"
                      borderWidth="1px"
                      borderColor={colors.neutralMedium}
                    >
                      {customActivities.map((activity) => (
                        <OptionBadge
                          className={classNames({
                            selected: (
                              currentPreferences?.activities || []
                            ).includes(activity),
                          })}
                          onClick={() => {
                            updatePreferences({
                              activities: activities.includes(activity)
                                ? activities.filter((n) => n !== activity)
                                : [...activities, activity],
                            });
                          }}
                        >
                          {activity}
                        </OptionBadge>
                      ))}
                    </NewCard>
                  </>
                )}
              </FormField>
              <Pane
                is="form"
                display="flex"
                marginTop="1rem"
                onSubmit={(e: React.FormEvent) => {
                  e.preventDefault();
                  if (customActivity) {
                    updatePreferences({
                      activities: [...activities, customActivity],
                    });
                    setCustomActivity("");
                  }
                }}
              >
                <TextInput
                  width="100%"
                  placeholder="Add your own!"
                  borderTopRightRadius="0"
                  borderBottomRightRadius="0"
                  value={customActivity}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCustomActivity(e.target.value)
                  }
                />
                <IconButton
                  icon={<PlusIcon color="white" />}
                  appearance="primary"
                  borderRadius="5px"
                  borderTopLeftRadius="0"
                  borderBottomLeftRadius="0"
                  height="46px"
                />
              </Pane>
            </>
          )}
        </>
      )}
      <CancelSparkConfirmationDialog
        onCancelReservation={() => {
          setCurrentPreferences(null);
          navigate("/events");
        }}
        onCloseComplete={() => setCancelConfirmationVisible(false)}
        isShown={cancelConfirmationVisible}
      />
    </Pane>
  );
};
