import { Pane } from "evergreen-ui";
import { NavLink } from "react-router-dom";
import { useGFTheme } from "../../lib/themes";
import houseSolid from "./images/house-solid.svg";
import houseOutline from "./images/house-outline.svg";
import userSolid from "./images/user-solid.svg";
import userOutline from "./images/user-outline.svg";
import gearSolid from "./images/gear-solid.svg";
import gearOutline from "./images/gear-outline.svg";

export const LoggedInNav = () => {
  const { colors } = useGFTheme();
  return (
    <Pane
      display="flex"
      backgroundColor={colors.greenLight}
      position="sticky"
      zIndex={11}
      top="0px"
      selectors={{
        "& a": {
          height: "4.5rem",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "0.625rem",
          fontWeight: 500,
          textTransform: "uppercase",
        },
        "& a img": {
          width: "1.5rem",
          height: "1.5rem",
        },
        "& a img.solid": {
          display: "none",
        },
        "& a img.outline": {
          display: "block",
        },
        "& a.loggedInNavActive img.solid": {
          display: "block",
        },
        "& a.loggedInNavActive img.outline": {
          display: "none",
        },
      }}
    >
      <NavLink
        to="/events"
        className={({ isActive }) => (isActive ? "loggedInNavActive" : "")}
      >
        <Pane is="img" src={houseSolid} className="solid" />
        <Pane is="img" src={houseOutline} className="outline" />
        <Pane marginTop="0.25rem">Home</Pane>
      </NavLink>
      <NavLink
        to="/profile"
        className={({ isActive }) => (isActive ? "loggedInNavActive" : "")}
      >
        <Pane is="img" src={userSolid} className="solid" />
        <Pane is="img" src={userOutline} className="outline" />
        <Pane marginTop="0.25rem">Profile</Pane>
      </NavLink>
      <NavLink
        to="/settings"
        className={({ isActive }) => (isActive ? "loggedInNavActive" : "")}
      >
        <Pane is="img" src={gearSolid} className="solid" />
        <Pane is="img" src={gearOutline} className="outline" />
        <Pane marginTop="0.25rem">Settings</Pane>
      </NavLink>
    </Pane>
  );
};
