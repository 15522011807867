import { useReq } from "@larner.dev/use-req";

import { IconButton, Pane, Paragraph, PlusIcon } from "evergreen-ui";
import { useGFTheme } from "../../../lib/themes";
import headshotAvatar from "./images/headshot_avatar.png";
import bodyAvatar from "./images/body_avatar.png";
import actionAvatar from "./images/action_avatar.png";
import friendsAvatar from "./images/friends_avatar.png";
import travelAvatar from "./images/travel_avatar.png";
import petAvatar from "./images/pet_avatar.png";
import { useCallback, useEffect, useState } from "react";
import { apiReq } from "../../../lib/apiReq";

import { PictureEditor } from "./PictureEditor";
import { PictureData } from "./pictureMeta";
import { Area } from "react-easy-crop";
import {
  MAX_MEDIA_BYTES,
  MediaKey,
  ProfilePictureKey,
  UserWithMedia,
} from "@greenflagdate/shared";
import { ReqMethod } from "@larner.dev/req";
import imageCompression from "browser-image-compression";
import { useStoreData } from "../../../lib/store/store";
import { H2 } from "../../../components/Heading/Heading";
import { useLoggedIn } from "../../../lib/useLoggedIn";

interface CroppedPictureProps {
  croppedArea: Area;
  imageSrc: string;
}

const CroppedPicture = ({ croppedArea, imageSrc }: CroppedPictureProps) => {
  const scale = 100 / croppedArea.width;
  const transform = {
    x: `${-croppedArea.x * scale}%`,
    y: `${-croppedArea.y * scale}%`,
    scale,
    width: "calc(100% + 0.5px)",
    height: "auto",
  };
  return (
    <Pane
      width="100%"
      height="100%"
      background="green"
      overflow="hidden"
      position="relative"
      paddingBottom="100%"
    >
      <Pane
        is="img"
        display="block"
        position="absolute"
        top={0}
        left={0}
        transformOrigin="top left"
        src={imageSrc}
        width={transform.width}
        height={transform.height}
        transform={`translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`}
      />
    </Pane>
  );
};

interface ThumbnailProps {
  src: string;
  onClick: () => void;
  picture?: PictureData;
}

const Thumbnail = ({ src, onClick, picture }: ThumbnailProps) => {
  const { colors } = useGFTheme();

  let pictureEl;
  if (picture) {
    pictureEl = (
      <CroppedPicture
        croppedArea={picture.cropArea}
        imageSrc={picture.imageSrc}
      />
    );
  } else {
    pictureEl = <Pane is="img" display="block" width="100%" src={src} />;
  }

  return (
    <Pane
      is="button"
      width="100%"
      borderStyle="solid"
      borderWidth="2px"
      borderRadius="0.3125rem"
      borderColor={colors.greenDark}
      position="relative"
      backgroundColor="transparent"
      cursor="pointer"
      selectors={{
        "&:active > .plusButton": {
          boxShadow: "none",
        },
      }}
      onClick={onClick}
    >
      {pictureEl}

      <IconButton
        is="div"
        className="plusButton"
        icon={<PlusIcon color={colors.greenDark} />}
        position="absolute"
        width="1.5rem"
        height="1.5rem"
        border="none"
        background={colors.greenLight}
        top="7%"
        right="7%"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.25)"
        pointerEvents="none"
      />
    </Pane>
  );
};

export const Profile = () => {
  const storeData = useStoreData();
  useLoggedIn(storeData);
  const [activePictureType, setActivePictureType] = useState<
    ProfilePictureKey | undefined
  >();
  const [pictures, setPictures] = useState<
    Partial<Record<ProfilePictureKey, PictureData>>
  >({});

  useEffect(() => {
    if (storeData.user?.media) {
      const keysToDownload = Object.keys(storeData.user.media);
      if (keysToDownload.length) {
        (async () => {
          const blobs = await Promise.all(
            keysToDownload.map(async (key) => {
              const media = storeData.user?.media[key as unknown as MediaKey];
              if (media) {
                const url = new URL(
                  media.urls.original,
                  import.meta.env.VITE_API_URL
                );
                return await fetch(url, {
                  headers: { Authorization: `Bearer ${storeData.auth.token}` },
                }).then(async (response) => {
                  if (!response.ok) {
                    return null;
                  }
                  const blob = await response.blob();
                  return {
                    key,
                    blob,
                    imageSrc: URL.createObjectURL(blob),
                    cropArea: {
                      width: media.crop_width_percent,
                      height: media.crop_height_percent,
                      x: media.crop_x,
                      y: media.crop_y,
                    },
                  };
                });
              }
              return null;
            })
          );
          const downloadedPictures: Partial<
            Record<ProfilePictureKey, PictureData>
          > = blobs
            .filter((b) => b !== null)
            .reduce((agg, { key, ...info }) => {
              return { ...agg, [key]: info };
            }, {});
          setPictures((pictures) => ({ ...downloadedPictures, ...pictures }));
        })();
      }
    }
  }, [storeData.auth.token, storeData.user?.media]);

  const [request] = useReq<UserWithMedia>(apiReq);
  const thumbnailClick = useCallback(
    (type: ProfilePictureKey) => () => setActivePictureType(type),
    []
  );

  return (
    <Pane
      maxWidth="40rem"
      marginX="auto"
      marginTop="1.5rem"
      paddingX="1.5rem"
      paddingBottom="2rem"
    >
      <H2>My Profile</H2>
      <Paragraph size={500} marginTop="1rem">
        Please upload at least one photo so that we can connect you with others.
        We recommend your first photo is a close up of your face so people can
        easily recognize you.
      </Paragraph>
      <Pane
        marginTop="1rem"
        display="grid"
        gap="0.5rem"
        gridTemplateColumns="repeat(3, calc(33.33% - 0.33rem))"
      >
        <Thumbnail
          src={headshotAvatar}
          onClick={thumbnailClick(ProfilePictureKey.Headshot)}
          picture={pictures[ProfilePictureKey.Headshot]}
        />
        <Thumbnail
          src={bodyAvatar}
          onClick={thumbnailClick(ProfilePictureKey.Body)}
          picture={pictures[ProfilePictureKey.Body]}
        />
        <Thumbnail
          src={actionAvatar}
          onClick={thumbnailClick(ProfilePictureKey.Action)}
          picture={pictures[ProfilePictureKey.Action]}
        />
        <Thumbnail
          src={friendsAvatar}
          onClick={thumbnailClick(ProfilePictureKey.Friends)}
          picture={pictures[ProfilePictureKey.Friends]}
        />
        <Thumbnail
          src={travelAvatar}
          onClick={thumbnailClick(ProfilePictureKey.Travel)}
          picture={pictures[ProfilePictureKey.Travel]}
        />
        <Thumbnail
          src={petAvatar}
          onClick={thumbnailClick(ProfilePictureKey.Pet)}
          picture={pictures[ProfilePictureKey.Pet]}
        />
      </Pane>
      <PictureEditor
        isShown={!!activePictureType}
        pictureType={activePictureType}
        onSave={async (data) => {
          setPictures({ ...pictures, [activePictureType!]: data });
          setActivePictureType(undefined);

          if (data.blob.size > MAX_MEDIA_BYTES) {
            data.blob = await imageCompression(data.blob as File, {
              maxSizeMB: MAX_MEDIA_BYTES / 1024 / 1024,
              useWebWorker: true,
            });
          }
          const formData = new FormData();
          // All metadata must be appended before the media at the end so that the server can read
          // metadata first before the file itself
          formData.append("key", activePictureType!);
          formData.append(
            "cropWidthPercentage",
            data.cropArea.width.toString()
          );
          formData.append(
            "cropHeightPercentage",
            data.cropArea.height.toString()
          );
          formData.append("cropX", data.cropArea.x.toString());
          formData.append("cropY", data.cropArea.y.toString());

          formData.append("media", data.blob, activePictureType);
          request[ReqMethod.PUT]("/media", formData);
        }}
        onClose={() => setActivePictureType(undefined)}
        blob={activePictureType && pictures[activePictureType]?.blob}
        cropArea={activePictureType && pictures[activePictureType]?.cropArea}
        onDelete={() => {
          if (activePictureType) {
            request[ReqMethod.DELETE](`/media/${activePictureType}`, {});
            const newPictures = { ...pictures };
            delete newPictures[activePictureType!];
            setPictures(newPictures);
            setActivePictureType(undefined);
          }
        }}
      />
    </Pane>
  );
};
