import { ProfilePictureKey } from "@greenflagdate/shared";
import { Area } from "react-easy-crop";
import headshotGood from "./images/headshot_good.jpg";
import headshotBad from "./images/headshot_bad.jpg";
import bodyGood from "./images/body_good.jpg";
import bodyBad from "./images/body_bad.jpg";
import actionGood1 from "./images/action_good1.jpg";
import actionGood2 from "./images/action_good2.jpg";
import friendsGood from "./images/friends_good.jpg";
import friendsBad from "./images/friends_bad.jpg";
import travelGood1 from "./images/travel_good1.jpg";
import travelGood2 from "./images/travel_good2.jpg";
import petGood1 from "./images/pet_good1.jpg";
import petGood2 from "./images/pet_good2.jpg";

interface PictureMetaImage {
  src: string;
  type: "good" | "bad";
}

interface PictureMetaValue {
  title: string;
  description: string;
  images: [PictureMetaImage, PictureMetaImage];
}

export const pictureMeta: Record<ProfilePictureKey, PictureMetaValue> = {
  [ProfilePictureKey.Headshot]: {
    title: "Headshot",
    description:
      "A clear, centered headshot with a smiling face is crucial for making a positive, approachable, and trustworthy first impression, ensuring immediate recognition and focus on you.",
    images: [
      { src: headshotGood, type: "good" },
      { src: headshotBad, type: "bad" },
    ],
  },
  [ProfilePictureKey.Body]: {
    title: "Head to Toe Shot",
    description:
      "Choose a photo that clearly shows your entire body from head to toe. This pic provides potential matches with more accurate sense of your physicality and style.",
    images: [
      { src: bodyGood, type: "good" },
      { src: bodyBad, type: "bad" },
    ],
  },
  [ProfilePictureKey.Action]: {
    title: "Hobby Shot",
    description:
      "Showcase your interests and lifestyle, giving potential matches a glimpse into your personality and what you enjoy doing, which can help spark meaningful connections.",
    images: [
      { src: actionGood1, type: "good" },
      { src: actionGood2, type: "good" },
    ],
  },
  [ProfilePictureKey.Friends]: {
    title: "Friends Shot",
    description:
      "A pic with your friends shows your social life and ability to build connections. Try to find one where you are in the center and easily recognizable.",
    images: [
      { src: friendsGood, type: "good" },
      { src: friendsBad, type: "bad" },
    ],
  },
  [ProfilePictureKey.Travel]: {
    title: "Location Shot",
    description:
      "Pick a unique location to spark conversation! This pic showcases your appreciation for different environments and settings, giving potential matches a glimpse into the places that hold meaning or interest for you.",
    images: [
      { src: travelGood1, type: "good" },
      { src: travelGood2, type: "good" },
    ],
  },
  [ProfilePictureKey.Pet]: {
    title: "Pet/Other Shot",
    description:
      "If you have a furry friend, create an instant conversation starter for fellow pet lovers. If you don’t add one of your favorite pics to finish off your photos!",
    images: [
      { src: petGood1, type: "good" },
      { src: petGood2, type: "good" },
    ],
  },
};

export interface Crop {
  x: number;
  y: number;
}

export interface PictureData {
  blob: Blob;
  cropArea: Area;
  imageSrc: string;
}
