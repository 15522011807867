import classNames from "classnames";
import styles from "./Hamburger.module.css";

interface HamburgerProps {
  open: boolean;
  onClick: (arg: boolean) => void;
  className?: string;
}

export function Hamburger({ open, onClick, className }: HamburgerProps) {
  return (
    <div
      className={classNames(styles.content, className, { [styles.open]: open })}
      id="icon"
      onClick={() => onClick(!open)}
    >
      <div className={styles.bar1} id="a"></div>
      <div className={styles.bar2} id="b"></div>
      <div className={styles.bar3} id="c"></div>
    </div>
  );
}
