import { Pane } from "evergreen-ui";
import { Outlet } from "react-router-dom";
import { Loading } from "../screens/Loading/Loading";
import { useStoreData } from "../../lib/store/store";

export const SparkContainer = () => {
  const storeData = useStoreData();
  if (storeData.loading) {
    return <Loading />;
  }
  return (
    <Pane
      width="100%"
      overflowX="hidden"
      selectors={{
        "&.push-enter.push-enter-active": {
          marginLeft: "0",
        },
        "&.push-enter": {
          marginLeft: "100vw",
          transition: "margin-left 200ms ease-in-out",
        },
        "&.push-exit.push-exit-active": {
          marginLeft: "-100vw",
        },
        "&.push-exit": {
          marginLeft: "0",
          transition: "margin-left 200ms ease-in-out",
        },
        "&.pop-enter.pop-enter-active": {
          marginLeft: "0",
        },
        "&.pop-enter": {
          marginLeft: "-100vw",
          transition: "margin-left 200ms ease-in-out",
        },
        "&.pop-exit.pop-exit-active": {
          marginLeft: "100vw",
        },
        "&.pop-exit": {
          marginLeft: "0",
          transition: "margin-left 200ms ease-in-out",
        },
      }}
    >
      <Outlet context={{ storeData }} />
    </Pane>
  );
};
