import { useEffect } from "react";
import { StoreSchema } from "./store/StoreSchema";
import { isLoggedIn } from "./isLoggedIn";
import { useLocation, useNavigate } from "react-router-dom";

export const useLoggedIn = (store: StoreSchema) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (!store.loading && !isLoggedIn(store)) {
      navigate(`/sign-in?r=${encodeURIComponent(pathname + search)}`, {
        replace: true,
      });
    }
  }, [navigate, pathname, search, store]);
};
