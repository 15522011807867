import { useCallback, useMemo, useState } from "react";
import { store, useStoreData } from "../../lib/store/store";
import { isLoggedIn } from "../../lib/isLoggedIn";
import {
  Alert,
  Button,
  FormField,
  Pane,
  PaneProps,
  TextInputField,
} from "evergreen-ui";
import { DateField } from "../DateField/DateField";
import { GenderPreferenceSelector } from "../GenderPreferenceSelector/GenderPreferenceSelector";
import { GenderSelector } from "../GenderSelector/GenderSelector";
import {
  GenderPreference,
  UserAttributes,
  UserWithMediaAndGroups,
} from "@greenflagdate/shared";
import { useReq } from "@larner.dev/use-req";
import { apiReq } from "../../lib/apiReq";
import { ActionType } from "../../lib/store/storeActions";
import { PhoneField } from "../PhoneField/PhoneField";

interface Errors {
  firstName?: string;
  birthDate?: string;
  gender?: string;
  genderPreference?: string;
  phoneNumber?: string;
}

interface UserRequiredInfoFormProps extends PaneProps<"div"> {
  buttonLabel: string;
  onSubmit?: () => void;
}

export const UserRequiredInfoForm = ({
  buttonLabel,
  onSubmit,
  ...props
}: UserRequiredInfoFormProps) => {
  const storeData = useStoreData();
  const [saveReq, saveReqState] = useReq<UserWithMediaAndGroups>(apiReq);
  const loggedIn = useMemo(() => isLoggedIn(storeData), [storeData]);
  const [errors, setErrors] = useState<Errors>({});
  const [firstName, setFirstName] = useState<string | undefined>(undefined);
  const [birthDate, setBirthDate] = useState<string | undefined>(undefined);
  const [gender, setGender] = useState<string | undefined>(undefined);
  const [phone, setPhone] = useState("");
  const [genderPreference, setGenderPreference] = useState<
    GenderPreference | undefined
  >(undefined);
  const submit = useCallback(async () => {
    const errors: Errors = {};
    const attributes: Partial<UserAttributes> = {};
    if (!storeData.user?.first_name) {
      if (!firstName) {
        errors.firstName = "Please enter your first name";
      } else {
        attributes.first_name = firstName;
      }
    }
    if (!storeData.user?.birth_date) {
      if (!birthDate) {
        errors.birthDate = "Please enter your birth date";
      } else {
        attributes.birth_date = birthDate;
      }
    }
    if (!storeData.user?.gender) {
      if (!gender) {
        errors.gender = "Please enter your gender";
      } else {
        attributes.gender = gender;
      }
    }
    if (!storeData.user?.gender_preference) {
      if (!gender) {
        errors.genderPreference = "Who do you want to date?";
      } else {
        attributes.gender_preference = genderPreference;
      }
    }
    if (
      !storeData.user?.phone_number &&
      !storeData.user?.phone_number_to_validate
    ) {
      if (!phone) {
        errors.phoneNumber = "Please enter your phone number";
      } else {
        attributes.phone_number_to_validate = phone;
      }
    }
    setErrors(errors);
    if (!Object.keys(errors).length) {
      const response = await saveReq.put("/user/attributes", attributes);
      if (response.success) {
        store.dispatch({
          type: ActionType.UpdateUser,
          params: {
            user: response.result,
          },
        });
        onSubmit && onSubmit();
      }
    }
  }, [
    birthDate,
    firstName,
    gender,
    genderPreference,
    onSubmit,
    phone,
    saveReq,
    storeData.user?.birth_date,
    storeData.user?.first_name,
    storeData.user?.gender,
    storeData.user?.gender_preference,
    storeData.user?.phone_number,
    storeData.user?.phone_number_to_validate,
  ]);
  if (!loggedIn) {
    return null;
  }
  const user = storeData.user!;
  return (
    <Pane {...props}>
      {saveReqState.error && <Alert title={saveReqState.error.message} />}
      {!user.first_name && (
        <TextInputField
          marginTop="1.5rem"
          value={firstName || ""}
          placeholder="First Name"
          label="First Name"
          validationMessage={errors.firstName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFirstName(e.target.value)
          }
        />
      )}
      {!user.birth_date && (
        <DateField
          label="Birth Date"
          value={birthDate || ""}
          onChange={setBirthDate}
          validationMessage={errors.birthDate}
        />
      )}

      {!user.gender && (
        <FormField
          label="What's your gender?"
          marginTop="1.5rem"
          validationMessage={errors.gender}
        >
          <GenderSelector
            value={gender}
            onChange={setGender}
            appearance="horizontal"
          />
        </FormField>
      )}

      {!user.gender_preference && (
        <FormField
          label="Who do you want to date?"
          marginTop="1.5rem"
          validationMessage={errors.genderPreference}
        >
          <GenderPreferenceSelector
            value={genderPreference}
            onChange={setGenderPreference}
            appearance="horizontal"
          />
        </FormField>
      )}
      {!user.phone_number_to_validate && !user.phone_number && (
        <PhoneField
          marginY="1.5rem"
          width="100%"
          label="Phone Number"
          onChange={(e) => setPhone(e.target.value)}
          value={phone}
          validationMessage={errors.phoneNumber}
        />
      )}
      <Button
        marginTop="1rem"
        appearance="primary"
        onClick={submit}
        isLoading={saveReqState.loading}
      >
        {buttonLabel}
      </Button>
    </Pane>
  );
};
