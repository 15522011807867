import { Pane, Paragraph } from "evergreen-ui";
import { useCallback, useMemo } from "react";
import { store } from "../../../lib/store/store";
import { ActionType } from "../../../lib/store/storeActions";
import { useNavigate } from "../../../lib/useNavigate";
import { useOutletContext } from "react-router-dom";
import { AppContext } from "../../app/App/appTypes";
import { ProgressScreen } from "../../../components/ProgressScreen/ProgressScreen";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { H2 } from "../../../components/Heading/Heading";
import dayjs from "dayjs";
import { sparkScreenMap } from "./sparkScreens";
import { GenderPreferenceSelector } from "../../../components/GenderPreferenceSelector/GenderPreferenceSelector";
import { GenderPreference } from "@greenflagdate/shared";

export const SparkGenderPreference = () => {
  const navigate = useNavigate();
  const { storeData } = useOutletContext<AppContext>();
  const age = useMemo(
    () =>
      storeData.sparkRegistration?.birthDate
        ? dayjs().diff(storeData.sparkRegistration.birthDate, "year")
        : 30,
    [storeData.sparkRegistration.birthDate]
  );
  const gap = Math.min(10, age - Math.ceil(age / 2 + 7));
  const ageRange: [number, number] = useMemo(
    () =>
      storeData.sparkRegistration.ageRange || [
        Math.max(18, age - gap),
        Math.min(80, age + gap),
      ],
    [age, gap, storeData.sparkRegistration.ageRange]
  );

  const next = useCallback(async () => {
    if (!storeData.sparkRegistration.ageRange) {
      store.dispatch({
        type: ActionType.UpdateSparkRegistration,
        params: {
          ageRange,
        },
      });
    }
    navigate(`/spark/${sparkScreenMap["gender-preference"].next}`);
  }, [ageRange, navigate, storeData.sparkRegistration.ageRange]);

  const select = useCallback((genderPreference?: GenderPreference) => {
    store.dispatch({
      type: ActionType.UpdateSparkRegistration,
      params: {
        genderPreference,
      },
    });
  }, []);
  return (
    <ProgressScreen
      title="Who do you want to date?"
      progress={sparkScreenMap["gender-preference"].progress}
      prev={`/spark/${sparkScreenMap["gender-preference"].prev}`}
      canContinue={!!storeData.sparkRegistration.genderPreference}
      onContinue={next}
    >
      <Pane
        display="flex"
        flexDirection="column"
        gap="0.75rem"
        alignItems="flex-end"
      >
        <GenderPreferenceSelector
          value={storeData.sparkRegistration.genderPreference}
          onChange={select}
        />
        <H2 marginTop="1.5rem" width="100%">
          Age Preference
        </H2>
        <Slider
          range
          min={18}
          max={70}
          value={ageRange}
          onChange={(ageRange) =>
            store.dispatch({
              type: ActionType.UpdateSparkRegistration,
              params: {
                ageRange: ageRange as [number, number],
              },
            })
          }
          styles={{ handle: { width: 30, height: 30, marginTop: "-12px" } }}
          // handleRender={() => <div className="rc-slider-handle" />}
        />
        <Paragraph size={500} width="100%" textAlign="center">
          {ageRange[0]} - {ageRange[1]}
          <br />
          years old
        </Paragraph>
      </Pane>
    </ProgressScreen>
  );
};
