import { useMemo, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { ProgressScreen } from "../../../components/ProgressScreen/ProgressScreen";
import { sparkScreenMap } from "./sparkScreens";
import { Paragraph } from "evergreen-ui";
import { AcceptResponse, loadAuthorizeAccept } from "../../../lib/authorize";
import { apiReq } from "../../../lib/apiReq";
import { useReq } from "@larner.dev/use-req";
import { useNavigate } from "../../../lib/useNavigate";
import { AppContext } from "../../app/App/appTypes";
import { useSparkActivityData } from "../../../lib/sparkHelpers";
import dayjs from "dayjs";
import { getMondayForSpark, SparkPreference } from "@greenflagdate/shared";
import { store } from "../../../lib/store/store";
import { ActionType } from "../../../lib/store/storeActions";
import {
  PaymentForm,
  PaymentFormHandle,
} from "../../../components/PaymentForm/PaymentForm";

interface Accept {
  dispatchData(
    secureData: unknown,
    responseHandler: (
      value: AcceptResponse | PromiseLike<AcceptResponse>
    ) => void
  ): void;
}

declare global {
  interface Window {
    Accept: Accept;
  }
}

loadAuthorizeAccept();

export const SparkCheckout = () => {
  const {
    storeData: { sparkRegistration },
  } = useOutletContext<AppContext>();
  const navigate = useNavigate();
  const paymentFormRef = useRef<PaymentFormHandle>(null);
  const [paymentFormLoading, setPaymentFormLoading] = useState(false);
  const [request, requestState] = useReq<SparkPreference>(apiReq);
  const { loading: sparkActivitiesLoading, sparkActivities } =
    useSparkActivityData();
  const hasPremium = useMemo(
    () =>
      sparkRegistration.activities?.some((a) =>
        sparkActivities.some(
          (ac) => ac.attributes.isPremium && ac.attributes.name === a
        )
      ),
    [sparkActivities, sparkRegistration.activities]
  );
  const hasBasic = useMemo(
    () =>
      sparkRegistration.activities?.some((a) =>
        sparkActivities.some(
          (ac) => !ac.attributes.isPremium && ac.attributes.name === a
        )
      ),
    [sparkActivities, sparkRegistration.activities]
  );
  const placementDate = useMemo(
    () =>
      sparkRegistration.availableDays?.length &&
      dayjs(sparkRegistration.availableDays[0])
        .startOf("week")
        .subtract(3, "days")
        .format("dddd, MMMM D"),
    [sparkRegistration.availableDays]
  );

  return (
    <ProgressScreen
      title="You're almost there"
      progress={sparkScreenMap["checkout"].progress}
      prev={`/spark/${sparkScreenMap["checkout"].prev}`}
      canContinue={true}
      titleAlign="center"
      continueButtonLabel="Complete Registration"
      onContinue={() => paymentFormRef.current?.submitForm()}
      isLoading={requestState.loading || paymentFormLoading}
      error={requestState.error?.message}
    >
      <Paragraph marginBottom="0.5rem" size={500}>
        <strong>You will only be charged if you are placed in a spark.</strong>
      </Paragraph>
      {!sparkActivitiesLoading && (
        <Paragraph marginBottom="1.5rem" size={500}>
          {hasPremium && !hasBasic ? (
            <>
              We will put a $30 hold on your credit card and only charge your
              card after you have been placed into a spark on {placementDate}.
            </>
          ) : hasBasic && !hasPremium ? (
            <>
              We will put a $15 hold on your credit card and only charge your
              card after you have been placed into a spark on {placementDate}.
            </>
          ) : (
            <>
              We will put a $30 hold on your credit card and only charge your
              card after you have been placed into a spark on {placementDate}.
              If you are placed into a basic spark you will only be charged $15.
            </>
          )}
        </Paragraph>
      )}

      <PaymentForm
        ref={paymentFormRef}
        width="100%"
        onLoadingStatusChange={setPaymentFormLoading}
        onSubmit={async () => {
          const currentMonday = getMondayForSpark().toISOString();
          const availableDays = sparkRegistration.availableDays?.filter((d) =>
            dayjs(d).isAfter(currentMonday)
          );
          const response = await request.put("/spark/preference", {
            neighborhoods: sparkRegistration.neighborhoods,
            availableDays,
            activities: sparkRegistration.activities,
            canLead: sparkRegistration.canLead,
          });
          if (response.success) {
            store.dispatch({
              type: ActionType.UpdateSparkRegistration,
              params: {
                availableDays: [],
                registrationKey: response.result.key,
              },
            });
            navigate("/events");
          }
        }}
      />
    </ProgressScreen>
  );
};
