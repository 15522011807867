import {
  CMSDataList,
  CMSItem,
  CMSMeta,
  CMSSparkActivityAttributes,
  CMSSparkNeighborhoodAttributes,
  SparkWithRegistrationSummary,
  UserWithMediaAndGroups,
} from "@greenflagdate/shared";
import { cmsRequest } from "./cmsHelpers";
import { ReqMethod } from "@larner.dev/req";
import { useEffect, useState } from "react";
import { getANetErrorMessage } from "./authorize";

export interface SparkActivityData
  extends CMSDataList<CMSSparkActivityAttributes> {
  meta: CMSMeta;
}

export interface SparkNeighborhoodData
  extends CMSDataList<CMSSparkNeighborhoodAttributes> {
  meta: CMSMeta;
}

export const sparkActivityPromise: Promise<SparkActivityData> =
  cmsRequest[ReqMethod.GET]<SparkActivityData>("/api/activities");

let sparkActivityPromiseResolved = false;
export const useSparkActivityData = () => {
  const [state, setState] = useState<{
    loading: boolean;
    sparkActivities: CMSItem<CMSSparkActivityAttributes>[];
  }>({
    loading: !sparkActivityPromiseResolved,
    sparkActivities: [],
  });
  useEffect(() => {
    sparkActivityPromise.then((sparkActivity) => {
      sparkActivityPromiseResolved = true;
      setState({ loading: false, sparkActivities: sparkActivity.data });
    });
  }, []);
  return state;
};

export const sparkNeighborhoodPromise: Promise<SparkNeighborhoodData> =
  cmsRequest[ReqMethod.GET]<SparkNeighborhoodData>("/api/spark-neighborhoods");

let sparkNeighborhoodPromiseResolved = false;
export const useSparkNeighborhoodData = () => {
  const [state, setState] = useState<{
    loading: boolean;
    sparkNeighborhoods: CMSItem<CMSSparkNeighborhoodAttributes>[];
  }>({
    loading: !sparkNeighborhoodPromiseResolved,
    sparkNeighborhoods: [],
  });
  useEffect(() => {
    sparkNeighborhoodPromise.then((sparkNeighborhood) => {
      sparkNeighborhoodPromiseResolved = true;
      setState({ loading: false, sparkNeighborhoods: sparkNeighborhood.data });
    });
  }, []);
  return state;
};

export const isSparkFull = (
  spark: SparkWithRegistrationSummary
): ("men" | "women" | "everyone")[] => {
  const result: ("men" | "women" | "everyone")[] = [];
  if (spark.capacity_men !== null) {
    if (spark.registrationSummary.men >= spark.capacity_men) {
      result.push("men");
    }
  }
  if (spark.capacity_women !== null) {
    if (spark.registrationSummary.women >= spark.capacity_women) {
      result.push("women");
    }
  }
  if (
    spark.registrationSummary.women +
      spark.registrationSummary.men +
      spark.registrationSummary.other >=
    spark.capacity
  ) {
    result.push("everyone");
  }
  return result;
};

export const getSparkErrorMessage = (
  code: string,
  _user: UserWithMediaAndGroups,
  spark: SparkWithRegistrationSummary,
  params?: Record<string, unknown>
): string => {
  switch (code) {
    case "NO_SPACE":
      return "Spots in this event are full.";
    case "EXCEEDS_CAPACITY":
      return "Spots in this event are full.";
    case "AGE_RESTRICTION":
      return spark.max_age
        ? `This event is for ages ${spark.min_age} - ${spark.max_age}`
        : `This event is for ages ${spark.min_age}+`;
    case "ANET_ERROR_CODE":
      return getANetErrorMessage(params?.code as string | undefined);
  }
  return "Something went wrong";
};

export const getAgeRangeString = (minAge: number, maxAge: number) =>
  maxAge ? `${minAge} - ${maxAge}` : `${minAge}+`;
