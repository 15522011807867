import { FormEventHandler, useCallback, useMemo, useState } from "react";
import { store } from "../../../lib/store/store";
import { ActionType } from "../../../lib/store/storeActions";
import { useNavigate } from "../../../lib/useNavigate";
import { useOutletContext } from "react-router-dom";
import { AppContext } from "../../app/App/appTypes";
import { ProgressScreen } from "../../../components/ProgressScreen/ProgressScreen";
import { sparkScreenMap } from "./sparkScreens";
import libpn from "google-libphonenumber";
import { UserWithMediaAndGroups } from "@greenflagdate/shared";
import { useReq } from "@larner.dev/use-req";
import { PhoneField } from "../../../components/PhoneField/PhoneField";
import { apiReq } from "../../../lib/apiReq";
import { Pane } from "evergreen-ui";

const phoneUtil = libpn.PhoneNumberUtil.getInstance();

export const SparkPhoneNumber = () => {
  const navigate = useNavigate();
  const { storeData } = useOutletContext<AppContext>();
  const [phoneOverride, setPhoneOverride] = useState<null | string>(null);
  const [error, setError] = useState<undefined | string>();
  const [userRequest, userRequestState] =
    useReq<UserWithMediaAndGroups>(apiReq);
  const phone = useMemo(() => {
    if (phoneOverride) {
      return phoneOverride;
    }
    if (storeData.user?.phone_number_to_validate) {
      let parsedNewPhoneNumber;
      try {
        parsedNewPhoneNumber = phoneUtil.parse(
          storeData.user.phone_number_to_validate
        );
        return phoneUtil.format(
          parsedNewPhoneNumber,
          libpn.PhoneNumberFormat.NATIONAL
        );
      } catch (error) {
        setError("It looks like this phone number is invalid");
        return storeData.user.phone_number_to_validate;
      }
    }
    return "";
  }, [phoneOverride, storeData.user?.phone_number_to_validate]);
  const next = useCallback(async () => {
    let parsedNewPhoneNumber;
    try {
      parsedNewPhoneNumber = phoneUtil.parse(phone, "US");
    } catch (error) {
      return setError("It looks like this phone number is invalid");
    }

    if (!phoneUtil.isValidNumberForRegion(parsedNewPhoneNumber, "US")) {
      return setError("It looks like this phone number is invalid");
    }
    const userResponse = await userRequest.put("/user/attributes", {
      phone_number_to_validate: phone,
    });
    if (userResponse.success) {
      store.dispatch({
        type: ActionType.UpdateUser,
        params: {
          user: userResponse.result,
        },
      });
      navigate(`/spark/${sparkScreenMap["phone"].next}`);
    }
  }, [navigate, phone, userRequest]);

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      next();
    },
    [next]
  );

  return (
    <ProgressScreen
      title="What's your phone number?"
      progress={sparkScreenMap["phone"].progress}
      prev={`/spark/${sparkScreenMap["phone"].prev}`}
      canContinue={!!phone}
      onContinue={next}
      error={userRequestState.error?.message}
    >
      <Pane is="form" onSubmit={onSubmit}>
        <PhoneField
          value={phone}
          onChange={(e) => {
            setPhoneOverride(e.target.value);
          }}
          validationMessage={error}
        />
      </Pane>
    </ProgressScreen>
  );
};
