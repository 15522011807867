import { TextInputField } from "evergreen-ui";
import { useNavigate } from "../../../lib/useNavigate";
import { useCallback, useMemo } from "react";
import { store } from "../../../lib/store/store";
import { ActionType } from "../../../lib/store/storeActions";
import { useOutletContext } from "react-router-dom";
import { AppContext } from "../../app/App/appTypes";
import { ProgressScreen } from "../../../components/ProgressScreen/ProgressScreen";
import { sparkScreenMap } from "./sparkScreens";

export const SparkName = () => {
  const navigate = useNavigate();
  const { storeData } = useOutletContext<AppContext>();
  const firstName = useMemo(
    () => storeData.sparkRegistration?.firstName || "",
    [storeData.sparkRegistration?.firstName]
  );
  const next = useCallback(async () => {
    navigate(`/spark/${sparkScreenMap["name"].next}`);
  }, [navigate]);
  return (
    <ProgressScreen
      title="What's your name?"
      progress={sparkScreenMap["name"].progress}
      prev={`/spark/${sparkScreenMap["name"].prev}`}
      canContinue={!!firstName.length}
      onContinue={next}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          next();
        }}
      >
        <TextInputField
          value={firstName}
          placeholder="First Name"
          label=""
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            store.dispatch({
              type: ActionType.UpdateSparkRegistration,
              params: {
                firstName: e.target.value,
              },
            })
          }
        />
      </form>
    </ProgressScreen>
  );
};
