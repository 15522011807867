import {
  MaskedCreditCardInfo,
  UserWithMediaAndGroups,
} from "@greenflagdate/shared";
import { useReq } from "@larner.dev/use-req";
import { Pane, PaneProps, Paragraph } from "evergreen-ui";
import {
  useState,
  useMemo,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { apiReq } from "../../lib/apiReq";
import { useStoreData } from "../../lib/store/store";
import { isLoggedIn } from "../../lib/isLoggedIn";
import { Loader } from "../Loader/Loader";

import AmericanExpress from "./images/AmericanExpress.png";
import MasterCard from "./images/MasterCard.png";
import Visa from "./images/Visa.png";
import Discover from "./images/Discover.png";
import { LinkButton } from "../LinkButton/LinkButton";
import {
  CreditCardForm,
  CreditCardFormErrors,
  CreditCardFormValue,
} from "./CreditCardForm";
import { isCreditCard, isPostalCode } from "validator";
import {
  AcceptResponse,
  getANetErrorMessage,
  loadAuthorizeAccept,
} from "../../lib/authorize";

const cardImages = {
  AmericanExpress,
  MasterCard,
  Visa,
  Discover,
};

interface PaymentFormProps extends PaneProps<"div"> {
  onSubmit?: () => void;
  onEditCardChange?: (editing: boolean) => void;
  onLoadingStatusChange?: (val: boolean) => void;
}

export interface PaymentFormHandle {
  submitForm: () => void;
}

export const PaymentForm = forwardRef<PaymentFormHandle, PaymentFormProps>(
  ({ onSubmit, onLoadingStatusChange, onEditCardChange, ...props }, ref) => {
    const storeData = useStoreData();
    const [creditCardValue, setCreditCardValue] = useState<CreditCardFormValue>(
      {
        name: "",
        cardNumber: "",
        cvc: "",
        expDate: "",
        postalCode: "",
      }
    );

    const [creditCardErrors, setCreditCardErrors] =
      useState<CreditCardFormErrors>();
    const [editingCard, setEditingCard] = useState(false);
    const [getCardReq, getCardReqState] = useReq<MaskedCreditCardInfo | null>(
      apiReq
    );
    const loggedIn = useMemo(() => isLoggedIn(storeData), [storeData]);
    const [putCardReq] = useReq<UserWithMediaAndGroups>(apiReq);
    const editCardChange = useCallback(
      (editing: boolean) => {
        setEditingCard(editing);
        if (onEditCardChange) {
          onEditCardChange(editing);
        }
      },
      [onEditCardChange]
    );
    useEffect(() => {
      if (loggedIn) {
        getCardReq.get("/user/card");
      }
    }, [getCardReq, loggedIn]);
    useEffect(() => {
      loadAuthorizeAccept();
    }, []);

    const submit = useCallback(async () => {
      if (
        !editingCard &&
        getCardReqState.result &&
        getCardReqState.result.postalCode
      ) {
        if (onSubmit) {
          onSubmit();
        }
        return;
      }
      const finalCardNumber = creditCardValue.cardNumber?.replace(/ /g, "");

      const errors: CreditCardFormErrors = {};

      if (!finalCardNumber) {
        errors.cardNumber = "Please enter your credit card number";
      } else if (!isCreditCard(finalCardNumber)) {
        errors.cardNumber = "Card number invalid";
      }

      if (!creditCardValue.cvc) {
        errors.cardNumber = "Please enter your credit card CVC";
      } else if (!/^[0-9]{3,4}$/.test(creditCardValue.cvc)) {
        errors.cvc = "CVC invalid";
      }
      if (!creditCardValue.name?.length) {
        errors.name = "Name is required";
      }

      const [expMonth, expYear] = (creditCardValue.expDate || "")
        .split("/")
        .map((v) => v.trim());
      if (expMonth.length !== 2 || expYear.length !== 2) {
        errors.expDate = "Epxiration date invalid";
      }

      if (!creditCardValue.postalCode?.length) {
        errors.postalCode = "Zip code is required";
      } else if (!isPostalCode(creditCardValue.postalCode, "US")) {
        errors.postalCode = "Please double check your zip code";
      }

      setCreditCardErrors(errors);
      if (Object.keys(errors).length) {
        return;
      }

      try {
        await loadAuthorizeAccept();
      } catch (error) {
        setCreditCardErrors({
          authorize: "Unable to connect to payment processor",
        });
        return;
      }

      const authData = {
        clientKey: import.meta.env.VITE_ANET_PUBLIC_CLIENT_KEY!,
        apiLoginID: import.meta.env.VITE_ANET_API_LOGIN_ID!,
      };

      const cardData = {
        cardNumber: finalCardNumber,
        month: expMonth,
        year: expYear,
        cardCode: creditCardValue.cvc,
      };

      const secureData = {
        authData,
        cardData,
      };

      const aNetResult: AcceptResponse = await new Promise((resolve) =>
        window.Accept.dispatchData(secureData, resolve)
      );

      if (aNetResult.messages.resultCode.toLowerCase() === "error") {
        if (aNetResult.messages.resultCode.toLowerCase() === "error") {
          if (aNetResult.messages.message[0].code) {
            return setCreditCardErrors({
              authorize: getANetErrorMessage(
                aNetResult.messages.message[0].code
              ),
            });
          }
        }

        return setCreditCardErrors({
          authorize: "Unknown credit card processing error.",
        });
      }

      if (!aNetResult.opaqueData) {
        setCreditCardErrors({
          authorize: "Something went wrong. Please try again.",
        });
        return;
      }

      const response = await putCardReq.put("/user/card", {
        opaqueData: aNetResult.opaqueData,
        postalCode: creditCardValue.postalCode,
      });
      if (response.success) {
        if (onSubmit) {
          onSubmit();
        }
        editCardChange(false);
      } else {
        if (response.error.code === "ANET_ERROR_CODE") {
          setCreditCardErrors({
            authorize: getANetErrorMessage(response.error.params?.code),
          });
        } else {
          setCreditCardErrors({
            authorize: getANetErrorMessage(response.error.message),
          });
        }
      }
    }, [
      creditCardValue.cardNumber,
      creditCardValue.cvc,
      creditCardValue.expDate,
      creditCardValue.name?.length,
      creditCardValue.postalCode,
      editCardChange,
      editingCard,
      getCardReqState.result,
      onSubmit,
      putCardReq,
    ]);

    useImperativeHandle(ref, () => ({
      submitForm: async () => {
        if (onLoadingStatusChange) {
          onLoadingStatusChange(true);
        }
        await submit();
        if (onLoadingStatusChange) {
          onLoadingStatusChange(false);
        }
      },
    }));

    return (
      <Pane {...props}>
        {!loggedIn ? (
          <Paragraph size={500}>
            You must register / log in before entering your credit card
            information.
          </Paragraph>
        ) : getCardReqState.loading ? (
          <Loader />
        ) : getCardReqState.result &&
          getCardReqState.result.postalCode &&
          !editingCard ? (
          <>
            <Paragraph size={300} marginBottom="0.25rem">
              CURRENT PAYMENT METHOD
            </Paragraph>
            <Pane display="flex" alignItems="center" gap="0.5rem">
              <Pane
                is="img"
                src={cardImages[getCardReqState.result.cardType]}
                height="1.25rem"
              />
              <Pane>{getCardReqState.result.maskedCardNumber}</Pane>
              <LinkButton onClick={() => editCardChange(true)}>
                Change Credit Card
              </LinkButton>
            </Pane>
          </>
        ) : (
          <CreditCardForm
            onCancel={editingCard ? () => editCardChange(false) : undefined}
            value={creditCardValue}
            onChange={setCreditCardValue}
            errors={creditCardErrors}
          />
        )}
      </Pane>
    );
  }
);
