import { useCallback } from "react";
import { useNavigate } from "../../../lib/useNavigate";
import { useOutletContext } from "react-router-dom";
import { AppContext } from "../../app/App/appTypes";
import { ProgressScreen } from "../../../components/ProgressScreen/ProgressScreen";
import { sparkScreenMap } from "./sparkScreens";
import { Pane, Paragraph } from "evergreen-ui";
import { VerticalGroup } from "../../../components/VerticalGroup/VerticalGroup";
import { ToggleButton } from "../../../components/ToggleButton/ToggleButton";
import { store } from "../../../lib/store/store";
import { ActionType } from "../../../lib/store/storeActions";
import { H3 } from "../../../components/Heading/Heading";

export const SparkLeader = () => {
  const navigate = useNavigate();
  const { storeData } = useOutletContext<AppContext>();
  const next = useCallback(async () => {
    navigate(`/spark/${sparkScreenMap["leader"].next}`);
  }, [navigate]);

  return (
    <ProgressScreen
      title="Are you interested in being a Spark lead?"
      progress={sparkScreenMap["leader"].progress}
      prev={`/spark/${sparkScreenMap["leader"].prev}`}
      canContinue={"canLead" in storeData.sparkRegistration}
      onContinue={next}
    >
      <Paragraph size={500} marginBottom="2rem">
        Spark leads get $5 off each spark event that they lead and priority
        access to the Sparks they're interested in.
      </Paragraph>
      <VerticalGroup width="100%" marginBottom="2rem">
        <ToggleButton
          type="button"
          width="100%"
          onClick={() =>
            store.dispatch({
              type: ActionType.UpdateSparkRegistration,
              params: {
                canLead: true,
              },
            })
          }
          isActive={!!storeData.sparkRegistration?.canLead === true}
        >
          Yes
        </ToggleButton>
        <ToggleButton
          type="button"
          width="100%"
          onClick={() =>
            store.dispatch({
              type: ActionType.UpdateSparkRegistration,
              params: {
                canLead: false,
              },
            })
          }
          isActive={storeData.sparkRegistration?.canLead === false}
        >
          No
        </ToggleButton>
      </VerticalGroup>
      <H3 marginBottom="1rem">Responsibilities include:</H3>
      <Paragraph size={500}>
        <Pane is="ol" paddingLeft="1.25rem">
          <li>
            Arrive 15 minutes early to the event and check in with the venue if
            needed.
          </li>
          <li>
            Welcome other attendees as they arrive and make sure they know where
            to go.
          </li>
          <li>
            Provide feedback after the event so we can keep making them better!
          </li>
        </Pane>
      </Paragraph>
    </ProgressScreen>
  );
};
