export const sparkScreens = [
  "activities",
  "availability",
  "neighborhoods",
  "birth-date",
  "gender",
  "gender-preference",
  "name",
  "email",
  "email-verify",
  "leader",
  "phone",
  "checkout",
];

export const sparkScreenMap: Record<
  string,
  { prev?: string; next?: string; progress: number }
> = sparkScreens.reduce(
  (agg, screen, index) => ({
    ...agg,
    [screen]: {
      prev: index > 0 ? sparkScreens[index - 1] : "",
      next: sparkScreens[index + 1],
      progress: ((index + 1) / sparkScreens.length) * 100,
    },
  }),
  {}
);
