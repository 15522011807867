import { useCallback } from "react";
import { store } from "../../../lib/store/store";
import { ActionType } from "../../../lib/store/storeActions";
import { useNavigate } from "../../../lib/useNavigate";
import { useOutletContext } from "react-router-dom";
import { AppContext } from "../../app/App/appTypes";
import { GenderSelector } from "../../../components/GenderSelector/GenderSelector";
import { ProgressScreen } from "../../../components/ProgressScreen/ProgressScreen";
import { sparkScreenMap } from "./sparkScreens";

export const SparkGender = () => {
  const navigate = useNavigate();
  const { storeData } = useOutletContext<AppContext>();
  const next = useCallback(async () => {
    navigate(`/spark/${sparkScreenMap["gender"].next}`);
  }, [navigate]);

  return (
    <ProgressScreen
      title="What's your gender?"
      progress={sparkScreenMap["gender"].progress}
      prev={`/spark/${sparkScreenMap["gender"].prev}`}
      canContinue={!!storeData.sparkRegistration?.gender}
      onContinue={next}
    >
      <GenderSelector
        value={storeData.sparkRegistration?.gender}
        onChange={(gender) =>
          store.dispatch({
            type: ActionType.UpdateSparkRegistration,
            params: {
              gender,
            },
          })
        }
      />
    </ProgressScreen>
  );
};
