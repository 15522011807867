import {
  TextInputField,
  Checkbox,
  Paragraph,
  Button,
  Pane,
} from "evergreen-ui";
import { useCallback, useMemo, useState } from "react";
import isEmail from "validator/lib/isEmail";
import { useNavigate } from "../../../lib/useNavigate";
import {
  AddressComponentType,
  PublicCredentialRecord,
  UserAttributes,
  UserWithMediaAndGroups,
} from "@greenflagdate/shared";
import { useReq } from "@larner.dev/use-req";
import { apiReq } from "../../../lib/apiReq";
import { store } from "../../../lib/store/store";
import { ActionType } from "../../../lib/store/storeActions";
import { useOutletContext } from "react-router-dom";
import { AppContext } from "../../app/App/appTypes";
import { ProgressScreen } from "../../../components/ProgressScreen/ProgressScreen";
import { sparkScreenMap } from "./sparkScreens";

export const SparkEmail = () => {
  const navigate = useNavigate();
  const { storeData } = useOutletContext<AppContext>();
  const [emailOptIn, setEmailOptIn] = useState(true);
  const [request, requestState] = useReq<PublicCredentialRecord>(apiReq);
  const [updateUserRequest] = useReq<UserWithMediaAndGroups>(apiReq);
  const next = useCallback(async () => {
    if (storeData.auth.token) {
      const attributes: Partial<UserAttributes> = {};
      if ("birthDate" in storeData.sparkRegistration) {
        attributes.birth_date = storeData.sparkRegistration.birthDate;
      }
      if ("gender" in storeData.sparkRegistration) {
        attributes.gender = storeData.sparkRegistration.gender;
      }
      if ("genderPreference" in storeData.sparkRegistration) {
        attributes.gender_preference =
          storeData.sparkRegistration.genderPreference;
      }
      if ("firstName" in storeData.sparkRegistration) {
        attributes.first_name = storeData.sparkRegistration.firstName;
      }
      if (storeData.sparkRegistration.ageRange) {
        attributes.min_age_preference = storeData.sparkRegistration.ageRange[0];
        attributes.max_age_preference = storeData.sparkRegistration.ageRange[1];
      }
      const userResponse = await updateUserRequest.put(
        "/user/attributes",
        attributes
      );
      if (userResponse.success) {
        store.dispatch({
          type: ActionType.UpdateUser,
          params: { user: userResponse.result },
        });
      }
      navigate(`/spark/${sparkScreenMap["email-verify"].next}`);
      return;
    }
    if (storeData.auth.email && storeData.auth.emailCredentialId) {
      navigate(`/spark/${sparkScreenMap["email"].next}`);
      return;
    }
    if (!isEmail(storeData.auth.email || "")) {
      return;
    }

    const response = await request.post("/user", {
      email: storeData.auth.email,
    });
    if (response.success) {
      store.dispatch({
        type: ActionType.UpdateAuth,
        params: {
          emailCredentialId: response.result.id,
        },
      });

      navigate(`/spark/${sparkScreenMap["email"].next}`);
    }
  }, [
    navigate,
    request,
    storeData.auth.email,
    storeData.auth.emailCredentialId,
    storeData.auth.token,
    storeData.sparkRegistration,
    updateUserRequest,
  ]);
  const locality = useMemo(() => {
    const addressComponents = storeData.user?.address_components;
    const locality = addressComponents?.find((c) =>
      c.types.includes(AddressComponentType.locality)
    );
    return locality?.long_name;
  }, [storeData.user?.address_components]);
  return (
    <ProgressScreen
      title="What's your email address?"
      progress={sparkScreenMap["email"].progress}
      prev={`/spark/${sparkScreenMap["email"].prev}`}
      canContinue={
        !!storeData.auth.token || isEmail(storeData.auth.email || "")
      }
      onContinue={next}
      isLoading={requestState.loading}
      error={requestState.error?.code}
    >
      {!storeData.auth.token ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            next();
          }}
        >
          <TextInputField
            label=""
            value={storeData.auth.email || ""}
            type="email"
            placeholder="email@example.com"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              store.dispatch({
                type: ActionType.UpdateAuth,
                params: {
                  email: e.target.value,
                },
              })
            }
          />
          <Paragraph
            is="label"
            size={500}
            display="flex"
            alignItems="flex-start"
            gap="0.5rem"
          >
            <Checkbox
              display="inline-block"
              marginTop="0.25rem"
              checked={emailOptIn}
              onChange={(e) => setEmailOptIn(e.target.checked)}
            />
            <div>
              Receive {!!locality && <strong>{locality}</strong>} event
              announcements and updates
            </div>
          </Paragraph>
        </form>
      ) : (
        <Pane display="flex" marginBottom="1.625rem" alignItems="center">
          <Paragraph size={500} flex={1}>
            You have already verified your email:{" "}
            {storeData.user?.email || storeData.user?.email_to_validate}
          </Paragraph>
          <Button
            appearance="minimal"
            backgroundColor="transparent"
            // style={{ color: colors.greenDark }}
            selectors={{
              "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
              },
            }}
            onClick={() => {
              store.dispatch({
                type: ActionType.LogOut,
                params: {},
              });
            }}
          >
            Log out
          </Button>
        </Pane>
      )}
    </ProgressScreen>
  );
};
