import { Button, FileUploader, Pane, MimeType } from "evergreen-ui";
import { useGFTheme } from "../../../lib/themes";

interface SelectPictureButtonProps {
  onSelect: (file: File) => void;
}

export const SelectPictureButton = ({ onSelect }: SelectPictureButtonProps) => {
  const { colors } = useGFTheme();
  return (
    <Pane
      position="relative"
      height="2.8125rem"
      selectors={{
        "&:hover > button": {
          backgroundColor: colors.greenDark,
        },
      }}
    >
      <Button
        appearance="primary"
        position="absolute"
        pointerEvents="none"
        width="100%"
        zIndex={1}
      >
        Choose Photo
      </Button>
      <Pane
        height="2.8125rem"
        overflow="hidden"
        position="absolute"
        width="100%"
      >
        <FileUploader
          marginTop="-1rem"
          opacity={0}
          maxSizeInBytes={10000000} // 10MB
          maxFiles={1}
          acceptedMimeTypes={[
            MimeType.jpeg,
            MimeType.png,
            "image/heic" as MimeType,
          ]}
          onChange={([file]) => {
            onSelect(file);
          }}
        />
      </Pane>
    </Pane>
  );
};
